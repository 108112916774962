import React from 'react';
import styled from 'styled-components';
import {withDefaultMedia, Grid, GridRow, GridCell} from 'notes';

export const CollectorLogic = ({matches, headers, items}) => {
  return (
    <Grid column columns={matches.large ? 10 : matches.medium ? 6 : 4}>
      <HeaderRow columns={matches.large ? 10 : matches.medium ? 6 : 4}>
        <HeaderCell
          header
          stretch
          columns={matches.large ? 6 : matches.medium ? 3 : 2}
        >
          {headers[0]}
        </HeaderCell>
        <GridCell header columns={matches.large ? 2 : matches.medium ? 2 : 1}>
          <Ellipses>{headers[1]}</Ellipses>
        </GridCell>
        <GridCell
          header
          xEnd
          columns={matches.large ? 2 : matches.medium ? 1 : 1}
        >
          {headers[2]}
        </GridCell>
      </HeaderRow>
      {items &&
        items.map(item => (
          <BodyRow
            key={item.label}
            columns={matches.large ? 10 : matches.medium ? 6 : 4}
          >
            <BoldCell
              stretch
              columns={matches.large ? 6 : matches.medium ? 3 : 2}
            >
              <ItemIcon alt={item.label} src={item.icon} /> {item.label}
            </BoldCell>
            <GridCell columns={matches.large ? 2 : matches.medium ? 2 : 1}>
              {matches.small ? '03/28/20' : item.response_time}
            </GridCell>
            <GridCell xEnd columns={matches.large ? 2 : matches.medium ? 1 : 1}>
              {item.responses}
            </GridCell>
          </BodyRow>
        ))}
    </Grid>
  );
};

export const Collector = withDefaultMedia(CollectorLogic);

const Ellipses = styled.div`
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
`;

const BoldCell = styled(GridCell)`
  font-weight: 600;
`;

const HeaderCell = styled(GridCell)`
  padding: 0;
`;

const BodyRow = styled(GridRow)`
  border-bottom: 1px solid ${props => props.theme.colors.borderLight};
  height: 56px;
`;

const HeaderRow = styled(GridRow)`
  align-items: flex-end;
  border-bottom: 1px solid ${props => props.theme.colors.borderLight};
  height: 38px;
  padding-bottom: 6px;
  min-height: 38px;
`;

const ItemIcon = styled.img`
  margin-right: 12px;
`;
