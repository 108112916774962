import React from 'react';
import styled from 'styled-components';
import {H4, withDefaultMedia, GridRow, GridCell} from 'notes';

export const ChartLineLogic = ({
  items = {},
  scale = false,
  label,
  value,
  secondaryValue,
  active = false,
  matches,
  ...props
}) => {
  const maxValue = Math.max.apply(
    Math,
    items.map(item => {
      return item.value;
    })
  );
  const calcPercent = (value / maxValue) * 100;

  return (
    <Row hover={active} {...props}>
      <TitleCell stretch columns={matches.large ? 4 : 2}>
        <InnerColumn>
          <Title>{label}</Title>
          <PercentageBar percent={Math.round(calcPercent)} />
        </InnerColumn>
      </TitleCell>
      <Total columns={1}>{value}</Total>
      <Percentage columns={1}>{Math.round(secondaryValue * 100)}%</Percentage>
    </Row>
  );
};

export const ChartLine = withDefaultMedia(ChartLineLogic);

const InnerColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const Title = styled(H4)`
  font-size: 15px;
  font-weight: 600;
  margin-bottom: 11px;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
`;

const TitleCell = styled(GridCell)`
  white-space: nowrap;
  @media only screen and ${props => props.theme.media.small} {
    min-width: inherit;
    width: 156px;
  }
`;

const Row = styled(GridRow)`
  align-items: flex-end;
  background-color: ${props =>
    props.hover ? props.theme.colors.pendingLight : '#ffffff'};
  border-bottom: 1px solid
    ${props =>
      props.hover
        ? props.theme.colors.pending
        : props.theme.colors.disabledFill};
  height: 78px;
  padding: 16px 0;
  width: 100%;
`;

const sharedStyles = `
  align-items: flex-end;
  justify-content: flex-end;
  line-height: 16px;
`;

const Total = styled(GridCell)`
  font-weight: 400;
  ${sharedStyles};
`;

const Percentage = styled(GridCell)`
  color: ${props => props.theme.colors.disabledText};
  ${sharedStyles};
`;

const PercentageBar = styled.div`
  background-color: ${props => props.theme.colors.pending};
  border-radius: 8px;
  display: flex;
  height: 16px;
  width: ${props => props.percent}%;
`;
