import React from 'react';
import {withTheme} from 'styled-components';
import {withDefaultMedia} from 'notes';
import {ReactComponent as DarkLogoSmall} from 'Images/DarkLogoSmall.svg';
import {ReactComponent as DarkLogoLarge} from 'Images/DarkLogoLarge.svg';
import {ReactComponent as LightLogoSmall} from 'Images/LightLogoSmall.svg';
import {ReactComponent as LightLogoLarge} from 'Images/LightLogoLarge.svg';

const LogoInternal = withTheme(
  ({matches, theme, footer = false, dark = false}) => {
    if (footer) {
      return matches.large ? (
        <LightLogoSmall />
      ) : theme.dark ? (
        <DarkLogoSmall />
      ) : (
        <LightLogoSmall />
      );
    }
    return theme.dark || dark ? <DarkLogoLarge /> : <LightLogoLarge />;
  }
);

export const Logo = withDefaultMedia(LogoInternal);
