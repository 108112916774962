import React from 'react';
import styled from 'styled-components';
import {Switch, Route} from 'react-router-dom';
import {Main} from './Main';
import {Login} from './Login';
import {Privacy, TermsOfUse} from './Legal';
export * from './Layout';

export const Routes = () => (
  <Wrap>
    <Switch>
      <Route exact path="/privacy" component={Privacy} />
      <Route exact path="/terms" component={TermsOfUse} />
      <Route exact path="/:slug/:collector" component={Main} />
      <Route exact path="/:slug" component={Login} />
    </Switch>
  </Wrap>
);

const Wrap = styled.div`
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  max-width: 1304px;
`;
