import React from 'react';
import styled from 'styled-components';
import {H2, P, GridColumn} from 'notes';

const chartImage = require('Images/Illustration_Donut_Chart.png');
const contactsImage = require('Images/Illustration_Contact_Sheet.png');
const concertsImage = require('Images/Illustration_Concerts_Songs.png');

export const ExportCard = ({title, description, type, children}) => {
  var headerImage;
  switch (type) {
    case 1:
      headerImage = chartImage;
      break;
    case 2:
      headerImage = contactsImage;
      break;
    default:
      headerImage = concertsImage;
  }

  return (
    <Card yEnd>
      <HeaderGradient type={type}>
        <CardTitle>{title}</CardTitle>
        <CircleContainer>
          <img alt={title} src={headerImage} />
        </CircleContainer>
      </HeaderGradient>
      <CardContent>
        <CardDescription>{description}</CardDescription>
        {children}
      </CardContent>
    </Card>
  );
};

const HeaderGradient = ({type, children}) => {
  var graphic;
  switch (type) {
    case 1:
      graphic = <GraphicPurple>{children}</GraphicPurple>;
      break;
    case 2:
      graphic = <GraphicOrange>{children}</GraphicOrange>;
      break;
    default:
      graphic = <GraphicRed>{children}</GraphicRed>;
  }
  return graphic;
};

const CircleContainer = styled.div`
  background-color: #ffffff;
  border-radius: 68px;
  bottom: -68px;
  display: block;
  height: 136px;
  position: absolute;
  right: 16px;
  width: 136px;
`;

const Card = styled(GridColumn)`
  background-color: #ffffff;
  border-radius: 8px 8px 0 0;
  height: 546px;
  width: 33%;
  & + & {
    margin-left: 28px;
  }
`;

const GraphicPurple = styled.div`
  align-items: flex-end;
  background: linear-gradient(
    70deg,
    rgba(80, 62, 140, 1) 0%,
    rgba(80, 62, 140, 1) 22%,
    rgba(95, 73, 166, 1) 22%,
    rgba(95, 73, 166, 1) 41%,
    rgba(109, 85, 191, 1) 41%,
    rgba(109, 85, 191, 1) 60%,
    rgba(124, 96, 217, 1) 60%,
    rgba(124, 96, 217, 1) 100%
  );
  border-radius: 8px 8px 0 0;
  box-sizing: border-box;
  display: flex;
  min-height: 196px;
  padding-bottom: 32px;
  padding-left: 32px;
  position: relative;
  width: 100%;
`;

const GraphicOrange = styled(GraphicPurple)`
  background: linear-gradient(
    70deg,
    rgba(168, 106, 30, 1) 0%,
    rgba(168, 106, 30, 1) 22%,
    rgba(194, 122, 35, 1) 22%,
    rgba(194, 122, 35, 1) 41%,
    rgba(219, 138, 39, 1) 41%,
    rgba(219, 138, 39, 1) 60%,
    rgba(246, 155, 44, 1) 60%,
    rgba(246, 155, 44, 1) 100%
  );
`;

const GraphicRed = styled(GraphicPurple)`
  background: linear-gradient(
    70deg,
    rgba(122, 43, 44, 1) 0%,
    rgba(122, 43, 44, 1) 22%,
    rgba(148, 52, 54, 1) 22%,
    rgba(148, 52, 54, 1) 41%,
    rgba(173, 61, 63, 1) 41%,
    rgba(173, 61, 63, 1) 60%,
    rgba(199, 70, 72, 1) 60%,
    rgba(199, 70, 72, 1) 100%
  );
`;

const CardTitle = styled(H2)`
  color: #fff;
  font-weight: 700;
  text-transform: uppercase;
  max-width: 210px;
`;

const CardContent = styled(GridColumn)`
  border: 1px solid ${props => props.theme.colors.disabledText};
  border-radius: 0 0 8px 8px;
  border-top: none;
  height: 100%;
  padding: 32px;
  padding-top: 72px;
`;

const CardDescription = styled(P)`
  color: ${props => props.theme.colors.inputPlaceholderText};
  display: flex;
  font-weight: 300;
  height: 100%;
`;
