import React from 'react';
import styled from 'styled-components';
import {P, Link} from 'notes';
import {Layout} from '../';

export const Paragraph = styled(P)`
  font-size: 14px;
  margin: 6px 0;
`;

export const ParagraphTitle = styled(P)`
  font-weight: 600;
  margin: 24px 0 12px 0;
`;

export const PageLink = styled(Link)`
  display: inline-block;
  font-size: 14px;
  font-weight: 400;
  text-decoration: none;
`;

export const Privacy = () => {
  return (
    <Layout title="Privacy Policy">
      <Paragraph>Effective date: February 17, 2020</Paragraph>
      <Paragraph>
        We at Set the Set know you care about how your personal information is
        used and shared, and we take your privacy seriously. Please read the
        following to learn more about our Privacy Policy. By using or accessing
        the &ldquo;Services&rdquo; (as defined by the Terms of Use) in any
        manner, you acknowledge that you accept the practices and policies
        outlined in the Terms of Use and this Privacy Policy, and you hereby
        consent that we will collect, use, and share your information in the
        following ways.
      </Paragraph>
      <Paragraph>
        Remember that your use of Set the Set&rsquo;s Services is at all times
        subject to the <PageLink href="/terms">Terms &amp; Conditions</PageLink>
        , which incorporates this Privacy Policy. Any terms we use in this
        Policy without defining them have the definitions given to them in the{' '}
      </Paragraph>
      <ParagraphTitle>What does this Privacy Policy cover?</ParagraphTitle>
      <Paragraph>
        This Privacy Policy covers our treatment of information that we gather
        when you are accessing or using our Services, but not to the practices
        of companies we don&rsquo;t own or control, or people that we
        don&rsquo;t manage. We gather various types of Personal Information from
        our users, as explained in more detail below, and we use this Personal
        Information internally in connection with our Services, including to
        personalize, provide, and improve our services, to allow you to set up a
        user account and profile, to contact you and allow other users to
        contact you, to fulfill your requests for certain products and services,
        and to analyze how you use the Services. In certain cases, we may also
        share some Personal Information with third parties, but only as
        described below.
      </Paragraph>
      <Paragraph>
        We do not knowingly collect or solicit personal information from anyone
        under the age of 13. If you are under 13, please do not attempt to
        register for the Services or send any personal information about
        yourself to us. If we learn that we have collected personal information
        from a child under age 13 without verification of parental consent, we
        will delete that information as quickly as possible. If you believe that
        a child under 13 may have provided us personal information, please
        contact us at privacy@settheset.com .
      </Paragraph>
      <ParagraphTitle>
        Will Set the Set ever change this Privacy Policy?
      </ParagraphTitle>
      <Paragraph>
        We&rsquo;re constantly trying to improve our Services, so we may need to
        change this Privacy Policy from time to time as well, but we will alert
        you to changes by placing a notice on the www.settheset.com, by sending
        you an email, and/or by some other means. In any event, your use of the
        site will constitute your acceptance of any changes to this Privacy
        Policy. Please note that if you&rsquo;ve opted not to receive legal
        notice emails from us (or you haven&rsquo;t provided us with your email
        address), those legal notices will still govern your use of the
        Services, and you are still responsible for reading and understanding
        them. We encourage you to check this Privacy Policy and our Terms of Use
        frequently for updates.&lrm; If you use the Services after any changes
        to the Privacy Policy have been posted, that means you agree to all of
        the changes. Use of information we collect now is subject to the Privacy
        Policy in effect at the time such information is collected.
      </Paragraph>
      <ParagraphTitle>
        What Information does Set the Set Collect?
      </ParagraphTitle>
      <Paragraph>Information You Provide to Us:</Paragraph>
      <Paragraph>
        We receive and store any information you knowingly provide to us. For
        example, through the registration process and/or through your account
        settings, we may collect information by which you may be personally
        identified, such as your name, email address, phone number, zip code,
        third-party account credentials (for example, your log-in credentials
        for Facebook or other third party sites), and Third Party Account
        Information (as defined below) (&ldquo;Personal Information&rdquo;). If
        you provide your third-party account credentials to us or otherwise sign
        in to the Services through a third party site or service, you understand
        some content and/or information in those accounts (&ldquo;Third Party
        Account Information&rdquo;) may be transmitted into your account with us
        if you authorize such transmissions, and that Third Party Account
        Information transmitted to our Services is covered by this Privacy
        Policy. For example, you may provide us with your log-in credentials for
        Spotify which will cause information from Spotify to be shared with us,
        including your name and username, your profile picture, how many
        followers you have on Spotify, and your public playlists. Certain
        information may be required to register with us or to take advantage of
        some of our features.
      </Paragraph>
      <Paragraph>
        We may communicate with you if you&rsquo;ve provided us the means to do
        so. For example, if you&rsquo;ve given us your email address, we may
        send you promotional email offers on behalf of other businesses, or
        email you about your use of the Services. Also, we may receive a
        confirmation when you open an email from us. This confirmation helps us
        make our communications with you more interesting and improve our
        services. If you do not want to receive communications from us or
        promotional email offers on behalf of other businesses, please indicate
        your preference by emailing us at privacy@settheset.com .
      </Paragraph>
      <Paragraph>Information Collected Automatically</Paragraph>
      <Paragraph>
        Whenever you interact with our Services, we automatically receive and
        record information on our server logs from your browser or device, which
        may include your IP address, geolocation data, device identification,
        &ldquo;cookie&rdquo; information, information received from web beacons,
        embedded scripts, browser fingerprinting, iBeacons, ETags (or
        &lrm;&lrm;&ldquo;entity tags&rdquo;), &lrm;the type of browser and/or
        device you&rsquo;re using to access our Services, and the page or
        feature you requested. &ldquo;Cookies&rdquo; are identifiers we transfer
        to your browser or device that allow us to recognize your browser or
        device and tell us how and when pages and features in our Services are
        visited and by how many people. You may be able to change the
        preferences on your browser or device to prevent or limit your
        device&rsquo;s acceptance of cookies, but this may prevent you from
        taking advantage of some of our features. Our advertising partners may
        also transmit cookies to your browser or device, when you click on ads
        that appear on the Services. Also, if you click on a link to a third
        party website or service, a third party may also transmit cookies to
        you. Again, this Privacy Policy does not cover the use of cookies by any
        third parties, and we do not control and aren&rsquo;t responsible for
        their privacy policies and practices. We may use this data to customize
        content for you that we think you might like, based on your usage
        patterns. We may also use it to improve the Services &ndash; for
        example, this data can tell us how often users use a particular feature
        of the Services, and we can use that knowledge to make the Services
        interesting to as many users as possible. This data does not aim to
        identify you personally; it simply enables us to compile statistics
        about our visitors &lrm;and their use of the&lrm; Services.{' '}
      </Paragraph>
      <ParagraphTitle>Analytics Services and Targeted Ads</ParagraphTitle>
      <Paragraph>
        Our Services use third-party analytics services, including, but not
        necessarily limited to, Google Analytics. &lrm;These services may track
        details about your online activities over time and across different
        sites. &lrm;These services help us to improve our Services. These
        services may also allow us to &lrm;provide you with targeted
        advertisements or other content that you may be interested in based on
        &lrm;your online activities. You can learn more about Google Analytics
        and your choices at this{' '}
        <PageLink
          class="c0"
          target="_blank"
          href="https://www.google.com/url?q=https://support.google.com/analytics/answer/6004245&amp;sa=D&amp;ust=1580844525810000"
        >
          link
        </PageLink>
        . If you would like to learn more &lrm;about targeted ads that may be
        based on your online activities (and the choices that you may
        &lrm;exercise for certain sites and advertisers) you may wish to visit
        the{' '}
        <PageLink
          class="c0"
          target="_blank"
          href="https://www.google.com/url?q=https://www.networkadvertising.org/%25E2%2580%258E&amp;sa=D&amp;ust=1580844525811000"
        >
          Network Advertising Initiative
        </PageLink>
        &nbsp;or the&nbsp;
        <PageLink
          class="c0"
          target="_blank"
          href="https://www.google.com/url?q=https://digitaladvertisingalliance.org/%25E2%2580%258E&amp;sa=D&amp;ust=1580844525811000"
        >
          Digital Advertising Alliance
        </PageLink>
        &nbsp;Web sites. In addition, you may prevent the collection of the data
        generated by the cookie and related to your use of the website
        (including your IP address) by Google as well as the processing of this
        data by Google by downloading and installing the browser plug-in
        available under the following{' '}
        <PageLink
          class="c0"
          target="_blank"
          href="https://www.google.com/url?q=https://tools.google.com/dlpage/gaoptout?hl%3Den&amp;sa=D&amp;ust=1580844525812000"
        >
          link
        </PageLink>
        .
      </Paragraph>
      <ParagraphTitle>Do Not Track</ParagraphTitle>
      <Paragraph>
        Do Not Track is a technology that enables users to opt out of tracking
        by websites they do not visit. &lrm;&lrm;Currently, we do not monitor or
        take any action with respect to Do Not Track technology. Please be aware
        that cookies placed by third parties may continue to track your
        activities online even after you have left our Services, and those third
        parties may not honor &ldquo;Do Not Track&rdquo; requests you have set
        using your browser or device.
      </Paragraph>
      <ParagraphTitle>
        Will Set the Set Share Any of the Personal Information it Receives?
      </ParagraphTitle>
      <Paragraph>
        We may share your Personal Information with third parties as described
        in this section:
      </Paragraph>
      <Paragraph>
        Information that&rsquo;s been de-identified . We may de-identify your
        Personal Information so that you are not identified as an individual,
        and provide that information to our partners. We may also provide
        aggregate usage information to our partners (or allow partners to
        collect that information from you), who may use such information to
        understand how often and in what ways people use our Services, so that
        they, too, can provide you with an optimal online experience.
      </Paragraph>
      <Paragraph>
        Merchant Partners, Artists, and Advertisers: We allow advertisers,
        artists and/or merchant partners (&ldquo;Partners&rdquo;) to choose the
        demographic information of users who will see their advertisements
        and/or promotional offers and we may provide information we have
        collected from you to such Partners, in two different formats.
      </Paragraph>
      <Paragraph>
        First, we may provide any of the information that we have collected from
        you in non-personally identifiable form to a Partner, in order for that
        Partner to select the appropriate audience for those advertisements
        and/or offers. Or, we might allow Partners to display their ads to users
        with similar usage patterns to yours. Note that if a Partner asks us to
        show an ad to a certain audience or audience segment and you respond to
        that ad, the Partner may conclude that you fit the description of the
        audience they were trying to reach.
      </Paragraph>
      <Paragraph>
        Second, if you have provided us with an email address, we may provide
        that email address (along with your name) to a Partner, who may send you
        promotional offers directly, for products or services they believe you
        may be interested in. Or, for example, if you have provided us with an
        email address as part of an artist-specific interaction with the
        Services, we may provide that email address to the artist, who may add
        you to their mailing list. We do not give Partners the right to share
        your personal information with third parties.
      </Paragraph>
      <Paragraph>
        Also, we may deliver a file to you through the Services (known as a
        &ldquo;web beacon&rdquo;) from an ad network. Web beacons allow ad
        networks to provide de-identified, aggregated auditing, research and
        reporting for us and for advertisers. Web beacons also enable ad
        networks to serve targeted advertisements to you when you visit other
        websites. Because your web browser must request these advertisements and
        web beacons from the ad network&rsquo;s servers, these companies can
        view, edit, or set their own cookies, just as if you had requested a web
        page from their site. You may be able to opt-out of web beacon tracking
        conducted by third parties through our Services by adjusting the Do Not
        Track settings on your browser; please note that we don&rsquo;t control
        whether or how these third parties comply with Do Not Track requests.
      </Paragraph>
      <Paragraph>
        Affiliated Businesses: In certain situations, businesses or third party
        websites we&rsquo;re affiliated with may sell or provide products or
        services to you through or in connection with the Services (either alone
        or jointly with us). You can recognize when an affiliated business is
        associated with such a transaction or service, and we will share your
        Personal Information with that affiliated business only to the extent
        that it is related to such transaction or service. One such service may
        include the ability for you to automatically transmit Third Party
        Account Information to your Services profile or to automatically
        transmit information in your Services profile to your third party
        account; for example, posting your Set the Set playlist to your Spotify
        account. We have no control over the policies and practices of third
        party websites or businesses as to privacy or anything else, so if you
        choose to take part in any transaction or service relating to an
        affiliated website or business, please review all such business&rsquo;
        or websites&rsquo; policies.
      </Paragraph>
      <Paragraph>
        Agents: We employ other companies and people to perform tasks on our
        behalf and need to share your information with them to provide products
        or services to you. For example, sometimes a third party may have access
        to your information in order to support &lrm;our information technology
        or to handle mailings on our behalf.&lrm; Unless we tell you
        differently, our agents do not have any right to use the Personal
        Information we share with them beyond what is necessary to assist us.
      </Paragraph>
      <Paragraph>
        User Profiles and Submissions: Certain user profile information,
        including your name, location, and any video or image content that such
        user has uploaded to the Services, may be displayed to other users to
        facilitate user interaction within the Services or address your request
        for our services. Your account privacy settings may allow you to limit
        the other users who can see the Personal Information in your user
        profile and/or what information in your user profile is visible to
        others. Please remember that any content you upload to your public user
        profile, along with any Personal Information or content that you
        voluntarily disclose online in a manner other users can view (on
        discussion boards, in messages and chat areas, etc.) becomes publicly
        available, and can be collected and used by anyone. Your user name may
        also be displayed to other users if and when you send messages or
        comments or upload images or videos through the Services and other users
        can contact you through messages and comments. Additionally, if you sign
        into the Services through a third party social networking site or
        service, your list of &ldquo;friends&rdquo; from that site or service
        may be automatically imported to the Services, and such
        &ldquo;friends,&rdquo; if they are also registered users of the
        Services, may be able to access certain non-public information you have
        entered in your Services user profile. Again, we do not control the
        policies and practices of any other third party site or service.
      </Paragraph>
      <Paragraph>
        Business Transfers: We may choose to buy or sell assets, and may share
        and/or transfer information in connection with the evaluation of and
        entry into such transactions. Also, if we (or our assets) are acquired,
        or if we go out of business, enter bankruptcy, or go through some other
        change of control, Personal Information could be one of the assets
        transferred to or acquired by a third party.
      </Paragraph>
      <Paragraph>
        Protection of Set the Set and Others: We reserve the right to access,
        read, preserve, and disclose any information that we believe is
        necessary to comply with law or court order; enforce or apply our{' '}
        &nbsp;and other agreements; protect the rights, property, or safety of
        Set the Set, our employees, our users, or others or to interact with
        anti-fraud databases, protect your vital interests, or protect the
        security or integrity &lrm;of our databases or the&lrm; Services.{' '}
      </Paragraph>
      <ParagraphTitle>Is Personal Information about me secure?</ParagraphTitle>
      <Paragraph>
        Your account is protected by a password for your privacy and security.
        The security measures in place on the Website and computer systems aim
        to protect the loss, misuse &lrm;or alteration of the information you
        provide to us.&lrm; If you access your account via a third party site or
        service, you may have additional or different sign-on protections via
        that third party site or service. You must prevent unauthorized access
        to your account and Personal Information by selecting and protecting
        your password and/or other sign-on mechanism appropriately and limiting
        access to your computer or device and browser by signing off after you
        have finished accessing your account.
      </Paragraph>
      <Paragraph>
        We endeavor to protect the privacy and security of your account and
        other Personal Information we hold in our records, but unfortunately, we
        cannot guarantee complete security. Unauthorized entry or use, hardware
        or software failure, and other factors, may compromise the security of
        user information at any time.
      </Paragraph>
      <ParagraphTitle>What Personal Information can I access?</ParagraphTitle>
      <Paragraph>
        Through your account settings, you may access, and, in some cases, edit
        or delete, information you&rsquo;ve provided to us including, for
        example:
      </Paragraph>
      <Paragraph>name and password</Paragraph>
      <Paragraph>email address</Paragraph>
      <Paragraph></Paragraph>
      <Paragraph>
        user profile information, including images and files you have uploaded
        to the site
      </Paragraph>
      <Paragraph></Paragraph>
      <Paragraph>
        The information you can view, update, and delete may change as the
        Services change. If you have any questions about viewing or updating
        information we have on file about you, please contact us at{' '}
        privacy@settheset.com . Upon receipt of your request and enough
        information to permit us to identify you, &lrm;we will correct or amend
        any &lrm;information that is inaccurate and notify any third party
        recipients of the necessary changes.&lrm;
      </Paragraph>
      <ParagraphTitle>Your California privacy rights</ParagraphTitle>
      <Paragraph>
        We may from time to time elect to share certain information about
        &lrm;you collected by us on the Services &lrm;with third parties for
        those third parties&rsquo; direct marketing purposes. &nbsp;California
        Civil Code Section &lrm;&lrm;1798.83 permits California residents who
        have supplied personal &lrm;information, as defined in the &lrm;statute,
        to us to, under certain circumstances, to request and obtain
        &lrm;certain information regarding &lrm;our disclosure, if any, of
        personal information to third parties for their &lrm;direct marketing
        purposes. If &lrm;this applies, you may obtain the categories of
        personal information &lrm;shared and the names and &lrm;addresses of all
        third parties that received personal information for their &lrm;direct
        marketing purposes &lrm;during the immediately prior calendar year (e.g.
        requests made in 2019 will &lrm;receive information &lrm;about 2018
        sharing activities) or to request to opt-out of such future sharing.
        &lrm;To make such a &lrm;request, please provide sufficient information
        for us to determine if this applies to &lrm;you, attest to the &lrm;fact
        that you are a California resident and provide a current California
        address for &lrm;our response. &nbsp;&lrm;You may make this request in
        writing at&lrm; privacy@settheset.com. &nbsp;
      </Paragraph>
      <ParagraphTitle>
        What other information should I know if I live in the European Economic
        Area?
      </ParagraphTitle>
      <Paragraph>
        This section of this Privacy Policy applies only if you use the Services
        from a country that is a Member State of the European Economic Area, and
        supplements the information in this Privacy Policy.
      </Paragraph>
      <Paragraph>
        We process personal data for the purposes set out in this Privacy
        Policy, as described above. Our legal basis to process personal data
        includes processing that is: necessary to offer the Services that you
        request to use (for example, to provide you with the features and
        products you request and to identify and authenticate you so you may use
        the Services); necessary to comply with legal requirements (for example,
        to comply with applicable accounting rules and to make mandatory
        disclosures to law enforcement); necessary for our legitimate interests
        (for example, to manage our relationship with you and to improve the
        Services provided to you); and based on consent by our customers (for
        example, to communicate with you about our products, features, and
        Services and provide you with marketing information), which may
        subsequently be withdrawn at any time (by using preference settings in
        emails or by contacting us with a request to update your preference
        settings by emailing privacy@settheset.com ) without affecting the
        lawfulness of processing based on consent before its withdrawal.
      </Paragraph>
      <Paragraph>
        If you are from the European Union or the United Kingdom, upon request,
        free of charge, you have the right to:
      </Paragraph>

      <Paragraph>
        - access and obtain a copy of the personal information we hold about
        you;
      </Paragraph>
      <Paragraph>
        - obtain information about the purposes for which we process your
        personal information and the categories of personal information
        concerned;
      </Paragraph>
      <Paragraph>
        - obtain information on the recipients or categories of recipients
        (including international recipients) to whom your personal information
        has been or will be disclosed;
      </Paragraph>
      <Paragraph>
        - transfer of your personal information from us to another data
        controller;
      </Paragraph>
      <Paragraph>
        - in certain circumstances, erasure of all personal information we hold
        about you;
      </Paragraph>
      <Paragraph>
        - lodge a complaint to the supervisory authority in your jurisdiction in
        respect of our collection or use of your personal information; and
      </Paragraph>
      <Paragraph>
        - withdraw your consent to our collection, use, storage, and
        dissemination of your data at any time.
      </Paragraph>

      <Paragraph>
        You can exercise your right at any time by emailing{' '}
        privacy@settheset.com.
      </Paragraph>
      <Paragraph>
        We may use automated decision making technologies, including profiling,
        to support our data processing activities. Our automated decision making
        capabilities include logic that attempts to identify products, offers,
        features or other services that we believe may interest you. By using
        this logic, it helps us personalize your interactions with Set The Set
        and our artners. For you, this means that you may see online
        advertisements, direct marketing communications (if you have
        subscribed), or other advertising or marketing messages or special
        offers based on your activity with our Services or interactions with Set
        The Set or artners or Affiliated Businesses.
      </Paragraph>
      <Paragraph>
        In some instances, you may be required to provide us with personal data
        for processing as described above, in order for us to be able to provide
        you with functionality of all the features of the Services.
        International and cross-border transfers The Services are controlled and
        operated from the United States; accordingly, this Privacy Policy, and
        our collection, use and disclosure of your personal information, is
        governed by the laws of the United States, and the Services are not
        intended to subject Set The Set or any of its affiliates to the laws or
        jurisdiction of any state, country or territory other than that of the
        United States. Your personal information may be stored and processed in
        any country where we have facilities or in which we engage service
        providers, and by using the Services you understand that your
        information will be transferred to countries outside of your country of
        residence, including the United States, which may have data protection
        rules that are different from those of your country.
      </Paragraph>
      <ParagraphTitle>Links to other websites</ParagraphTitle>
      <Paragraph>
        The Services may contain hyperlinks to websites that are not operated by
        us. These hyperlinks are &lrm;provided for your reference. We do not
        control these &lrm;websites and are not responsible for their data or
        privacy practices. We urge you to review any &lrm;privacy policy posted
        on any site you visit before using the site or providing any Personal
        Data &lrm;about yourself.&lrm;
      </Paragraph>
      <Paragraph>What choices do I have?</Paragraph>
      <Paragraph>
        You can always opt not to disclose information to us, but keep in mind
        some information may be needed to register with us or to take advantage
        of some of our features. You may be able to add, update, or delete
        information as explained above. When you update information, however, we
        may maintain a copy of the unrevised information in our records. Some
        information may remain in our records after your deletion of such
        information from your account or after deletion of your account
        entirely, and we may continue to use and disclose such information as
        set forth in this privacy policy. We may also use any aggregated data
        derived from or incorporating your Personal Information after you update
        or delete it.
      </Paragraph>
      <ParagraphTitle>
        What if I have questions about this policy?
      </ParagraphTitle>
      <Paragraph>
        If you have any questions or concerns regarding our privacy policies,
        please send us a detailed message to privacy@settheset.com we will try
        to resolve your concerns.
      </Paragraph>
      <Paragraph></Paragraph>
      <Paragraph>81597597v.9</Paragraph>
    </Layout>
  );
};
