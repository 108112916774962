import React, {Fragment, useState, useEffect} from 'react';
import styled from 'styled-components';
import {
  Footnote,
  CalendarDay,
  withDefaultMedia,
  Link,
  Grid,
  GridColumn,
  GridCell,
  GridRow,
} from 'notes';
import {formatDate} from 'Modules';
import {StyledLabel} from './SingleCriteriaGraph';
import {AudienceIcon} from 'Images';

const ConcertAttendanceLogic = ({matches, label, items}) => {
  const total = items.reduce((sum, item) => (sum += item.value), 0);
  const calcPercentage = value => (value / total) * 100;

  const [concerts, setConcerts] = useState('');
  const [listIncrement, setListIncrement] = useState(1);

  useEffect(() => {
    if (!!items) {
      const updateList = data => {
        let unfilteredSongs = data;
        let increment = listIncrement * 10;
        let newArray = [];
        for (var i = 0; i < increment; i++) {
          if (unfilteredSongs[i]) {
            newArray.push(unfilteredSongs[i]);
          }
        }
        setConcerts(newArray);
      };
      updateList(items);
    }
  }, [listIncrement, items]);

  return (
    <Fragment>
      <StyledLabel>{label}</StyledLabel>
      <Grid columns={matches.large ? 10 : 4}>
        {!!concerts &&
          concerts.map(({id, date, title, subText, value}) => (
            <Row row key={id} columns={matches.large ? 10 : 4}>
              <LabelCell row stretch columns={matches.large ? 8 : 4}>
                <Calendar
                  month={formatDate(date, {month: 'short'})}
                  day={formatDate(date, {day: 'numeric'})}
                />
                <ConcertContent>
                  <ConcertTitle>{title}</ConcertTitle>
                  <SubTitle>{subText}</SubTitle>
                </ConcertContent>
              </LabelCell>
              <Value columnLeft={matches.small ? 1 : 0} columns={1}>
                {!matches.large && (
                  <IconContainer>
                    <AudienceIcon />
                  </IconContainer>
                )}
                {value}
              </Value>
              <Percent columns={1}>{calcPercentage(value).toFixed(1)}%</Percent>
            </Row>
          ))}
        <LinkContainer>
          {concerts.length < items.length && (
            <ExpandLink onClick={() => setListIncrement(listIncrement + 1)}>
              View More
            </ExpandLink>
          )}
          {listIncrement !== 1 && (
            <ExpandLink onClick={() => setListIncrement(listIncrement - 1)}>
              View Less
            </ExpandLink>
          )}
        </LinkContainer>
      </Grid>
    </Fragment>
  );
};

export const ConcertAttendance = withDefaultMedia(ConcertAttendanceLogic);

const LinkContainer = styled(GridRow)`
  justify-content: flex-start;
  margin-top: 24px;
`;

const ExpandLink = styled(Link)`
  display: inline-block;
  & + & {
    margin-left: 40px;
  }
`;

const IconContainer = styled.div`
  align-items: center;
  display: flex;
  height: 22px;
  margin-right: 8px;
`;

const Calendar = styled(CalendarDay)`
  margin-right: 20px;
  min-width: 48px;
  position: relative;
  @media only screen and ${props => props.theme.media.large} {
    margin-right: 30px;
  }
`;

const LabelCell = styled(GridCell)`
  align-items: center;
  flex-grow: 100;
  margin-right: 24px;
  max-width: 70vw;

  @media only screen and ${props => props.theme.media.small} {
    align-items: flex-start;
    margin-right: 24px;
    max-width: 70vw;
  }
`;

const Row = styled(GridColumn)`
  align-items: center;
  border-bottom: 1px solid ${props => props.theme.colors.borderLight};
  flex-wrap: nowrap;
  min-height: 72px;
  padding-top: 12px;
  padding-bottom: 12px;

  @media only screen and ${props => props.theme.media.small} {
    align-items: flex-start;
    flex-wrap: wrap;
    min-height: 104px;
    padding-top: 16px;
    padding-bottom: 16px;
  }
`;

const ConcertContent = styled(GridColumn)`
  overflow: hidden;
  width: 100%;
`;

const ConcertTitle = styled(GridColumn)`
  font-weight: 600;
  font-size: 15px;
  line-height: 18px;
  margin-bottom: 4px;
`;

const SubTitle = styled(Footnote)`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: 100%;
  @media only screen and ${props => props.theme.media.small} {
    margin-bottom: 16px;
  }
`;

const Value = styled(GridCell)`
  font-weight: 400;
  align-items: ${props => (props.columnLeft === 1 ? 'flex-start' : 'flex-end')};
  flex-grow: 1;
  justify-content: flex-start;

  @media only screen and ${props => props.theme.media.large} {
    flex-grow: 0;
    justify-content: center;
  }
`;

const Percent = styled(GridCell)`
  color: ${props => props.theme.colors.disabledText};
  align-items: flex-start;
  justify-content: flex-start;
  flex-grow: 1;
  @media only screen and ${props => props.theme.media.medium} {
    align-items: flex-end;
    flex-grow: 0;
    justify-content: center;
  }
  @media only screen and ${props => props.theme.media.large} {
    align-items: flex-end;
    flex-grow: 0;
    justify-content: center;
  }
`;
