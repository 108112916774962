import React, {useState, useEffect} from 'react';
import styled from 'styled-components';
import {Select, Label, ButtonPrimary} from 'notes';
import {ReactComponent as ExportIcon} from 'Images/Export_Icon.svg';
import {_categories, _subcategory} from './modules';
export * from './Mobile';

export const Filter = ({onChange, onExportClick, loading, data, ...props}) => {
  var categories = _categories(data.labels);
  const [category, setCategory] = useState(categories[0]);
  const [filters, setFilters] = useState([]);
  const [subcategory, setSubcategory] = useState(categories[0]);

  useEffect(() => {
    if (category.id === 1) {
      setFilters([]);
      setSubcategory(categories[0]);
    } else {
      var options = _subcategory(data.filters, category.id);
      setFilters(options);
      setSubcategory(options[0]);
    }
  }, [category]);

  useEffect(() => {
    onChange({categoryId: category.id, subcategoryId: subcategory.id});
  }, [subcategory]);

  return (
    <Wrapper {...props}>
      <Container>
        <Flex>
          <LabelMargin>
            Filter responses by
            <SelectField
              options={categories}
              selected={category}
              onChange={setCategory}
              disabled={loading}
            />
          </LabelMargin>

          {!!filters.length > 0 && (
            <SelectField
              options={filters}
              selected={subcategory}
              onChange={setSubcategory}
              width={392}
              disabled={loading}
            />
          )}
        </Flex>
        <ButtonPrimary
          disabled={loading}
          onClick={() => onExportClick(true)}
          iconLeft={<Icon disabled={loading} />}
          className="print-hide"
        >
          Export Results
        </ButtonPrimary>
      </Container>
    </Wrapper>
  );
};

const Icon = styled(ExportIcon)`
  height: 14px;
  width: 14px;
  path {
    fill: ${props =>
      props.disabled ? props.theme.colors.disabledText : '#ffffff'};
  }
`;

const LabelMargin = styled(Label)`
  margin-right: 24px;
`;

const SelectField = styled(Select)`
  cursor: default;
  min-width: ${props => (props.width ? props.width : 184)}px;
`;

const Wrapper = styled.div`
  border-bottom: 1px solid #a7aeb2;
  display: block;
`;

const Container = styled.div`
  align-items: flex-end;
  display: flex;
  margin: 0 auto;
  max-width: 1016px;
  padding: 32px 0 40px 0;
  width: 100%;
`;

const Flex = styled.div`
  align-items: flex-end;
  display: flex;
  flex-grow: 1;
`;
