import React, {useState, useEffect} from 'react';
import styled from 'styled-components';
import {P, H3, GridRow} from 'notes';
import {CaretRightIcon} from 'Images';

export const MobileFilterButton = ({title, loading = false, ...props}) => {
  const [scrollPosition, setScrollPosition] = useState(0);
  const [shadow, setShadow] = useState(false);
  useEffect(() => {
    window.addEventListener('scroll', function(e) {
      setScrollPosition(window.pageYOffset);
    });
    if (scrollPosition > 430) {
      setShadow(true);
    } else {
      setShadow(false);
    }
  }, [scrollPosition]);
  return (
    <Container shadow={shadow} {...props}>
      <GridRow yCenter stretch columns={4}>
        <FilterLabel>Filter:</FilterLabel>
        <FilterSelection>
          {loading ? <LoadingText>Loading...</LoadingText> : title}
        </FilterSelection>
        <CaretRightIcon />
      </GridRow>
    </Container>
  );
};

const LoadingText = styled(P)`
  color: ${props => props.theme.colors.disabledText};
`;

const Container = styled.div`
  align-items: center;
  background-color: #fff;
  border-bottom: 1px solid ${props => props.theme.colors.disabledText};
  box-sizing: border-box;
  ${props => props.shadow && 'box-shadow: 0 1px 4px 0 rgba(0,0,0,0.48)'};
  display: flex;
  height: 56px;
  padding-left: 24px;
  padding-right: 24px;
  position: sticky;
  top: 0;
  width: 100%;
  z-index: 100;
`;

const FilterLabel = styled(P)`
  color: ${props => props.theme.colors.inputPlaceholderText};
  margin-right: 4px;
`;

const FilterSelection = styled(H3)`
  color: ${props => props.theme.colors.action};
  display: flex;
  flex-grow: 1;
  font-size: 17px;
`;
