import React, {Fragment, useContext} from 'react';
import styled from 'styled-components';
import {withDefaultMedia, H1, Subheader, FlexBox} from 'notes';
import {LightLogoLarge} from 'Images';
import {Logo} from 'Components';
import {ReportContext} from 'Providers';

export const Header = withDefaultMedia(({matches, simple, print, ...props}) => {
  const {artist} = useContext(ReportContext);

  return (
    <Fragment>
      {!matches.large && (
        <MobileLogoContainer>
          <LightLogoLarge />
        </MobileLogoContainer>
      )}
      <HeaderContainer print={print} {...props}>
        <ContentContainer>
          {matches.large && (
            <LogoContainer>
              <Logo />
            </LogoContainer>
          )}
          {!!artist.name && (
            <Title
              print={print}
              bottomMargin={!artist.tour}
              length={artist.name.length}
            >
              {artist.name}
            </Title>
          )}
          {!!artist.tour && <Subitle>{artist.tour}</Subitle>}
        </ContentContainer>

        <ImageContainer>
          <Gradient />
          <Image
            print={print}
            background={!!artist ? artist.image : undefined}
          />
        </ImageContainer>
      </HeaderContainer>
    </Fragment>
  );
});

const MobileLogoContainer = styled.div`
  align-items: center;
  border-bottom: 1px solid ${props => props.theme.colors.disabledText};
  box-sizing: border-box;
  display: flex;
  height: 48px;
  justify-content: center;
  width: 100%;
`;

const LogoContainer = styled.div`
  margin-bottom: 40px;
`;

const Title = styled(H1)`
  color: #ffffff;
  font-size: 32px;
  line-height: 38px;
  margin-bottom: 8px;
  text-transform: uppercase;
  text-align: center;
  width: 100%;
  @media only screen and ${props => props.theme.media.small} {
    margin-bottom: ${props => (props.bottomMargin ? '40px' : '8px')};
  }
  @media only screen and (max-width: 414px) {
    ${props => props.length > 16 && `font-size: 28px;`};
    ${props => props.length > 16 && `line-height: 34px;`};
  }
  @media only screen and ${props => props.theme.media.large} {
    color: ${props => (props.theme.dark ? '#FFFFFF' : '#222222')};
    font-size: 40px;
    ${props => props.length > 18 && `font-size: 32px;`};
    ${props => props.length > 18 && `line-height: 38px;`};
    margin-bottom: 4px;
    max-width: 442px;
    ${props =>
      props.print && `font-size: 40px; line-height: 40px; max-width: initial;`};
    text-align: left;
    width: auto;
  }
`;

const Subitle = styled(Subheader)`
  color: #a6aeb2;
  margin-bottom: 40px;
  text-transform: uppercase;
  text-align: center;
  width: 100%;
  @media only screen and ${props => props.theme.media.large} {
    color: ${props => (props.theme.dark ? '#FFFFFF' : '#222222')};
    color: #a6aeb2;
    margin-bottom: 24px;
    text-align: left;
    width: auto;
  }
`;

const HeaderContainer = styled(FlexBox)`
  background-color: ${props => (props.theme.dark ? '#000000' : '#FFFFFF')};
  flex-flow: row nowrap;
  justify-content: center;
  min-height: ${props => (props.print ? '250px' : '382px')};
  position: relative;
  @media only screen and ${props => props.theme.media.large} {
    border-bottom: ${props => (props.print ? 'none' : '1px solid #e7e9eb')};
  }
`;

const ContentContainer = styled(FlexBox)`
  align-items: center;
  box-sizing: border-box;
  color: ${props =>
    props.theme.dark ? props.theme.darkDefault : props.theme.lightDefault};
  flex-flow: column nowrap;
  justify-content: flex-end;
  position: absolute;
  width: 100%;
  z-index: 5;
  @media only screen and ${props => props.theme.media.small} {
    min-height: 382px;
    padding: 0 24px;
  }
  @media only screen and ${props => props.theme.media.medium} {
    min-height: 382px;
  }
  @media only screen and ${props => props.theme.media.large} {
    align-items: flex-start;
    height: 100%;
    justify-content: center;
    padding-top: 46px;
    max-width: 1016px;
    width: 100%;
    z-index: 2;
  }
`;

const ImageContainer = styled(FlexBox)`
  min-height: 100%;
  position: absolute;
  width: 100%;
  @media only screen and ${props => props.theme.media.large} {
    width: 55%;
    flex-grow: 0;
    right: 0;
  }
`;

const Gradient = styled.div`
  background: linear-gradient(0deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 100%);
  bottom: 0;
  height: 73%;
  left: 0;
  position: absolute;
  width: 100%;
  @media only screen and ${props => props.theme.media.large} {
    ${props =>
      props.theme.dark
        ? 'background: linear-gradient(90deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 100%);'
        : 'background: linear-gradient(90deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);'};
    bottom: inherit;
    height: 100%;
    top: 0;
    width: 104px;
  }
`;

const Image = styled.div`
  background-image: url(${props => props.background});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: ${props => (props.print ? 'none' : 'flex')};
  min-height: 100%;
  width: 100%;
`;
