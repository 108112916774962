import React from 'react';
import styled from 'styled-components';
import {FlexBox, Toolbox, H2, P, IconInformation, CardBorder} from 'notes';

const ContainerBottom = styled(FlexBox)`
  align-items: center;
`;

const Card = styled(CardBorder)`
  align-items: center;
  background-color: #ffffff;
  border-radius: 8px;
  box-sizing: border-box;
  display: flex;
  flex-flow: column wrap;
  flex-grow: 1;
  justify-content: flex-start;
  padding: 32px 24px;
  padding-bottom: 28px;
  position: relative;
  width: 100%;
  @media only screen and ${props => props.theme.media.small} {
    & + & {
      margin-top: 16px;
    }
  }
  @media only screen and ${props => props.theme.media.medium} {
    & + & {
      margin-left: 16px;
    }
  }
  @media only screen and ${props => props.theme.media.large} {
    & + & {
      margin-left: 28px;
    }
  }
`;

const Icon = styled(IconInformation)`
  position: absolute;
  right: 16px;
  top: 16px;
`;

const Title = styled(P)`
  color: ${props => props.theme.colors.text};
  font-size: 14px;
  font-weight: 600;
  line-height: 22px;
  margin-bottom: 20px;
`;

const Stat = styled(H2)`
  font-size: 32px;
  font-weight: 700;
  line-height: 38px;
`;

const Subtitle = styled(P)`
  color: ${props => props.theme.colors.inputPlaceholderText};
  font-size: 15px;
  text-align: center;
`;

export const KPI = ({title, type, value, description, tooltip}) => (
  <Card>
    <Title>{title}</Title>
    <Stat>
      {type === 1
        ? Number(value)
        : type === 3
        ? `$${Number(value)}`
        : Math.round(value * 100) + `%`}
    </Stat>
    <ContainerBottom>
      <Subtitle>{description}</Subtitle>
      {!!tooltip && (
        <Toolbox simple trigger={props => <Icon {...props} />}>
          {tooltip}
        </Toolbox>
      )}
    </ContainerBottom>
  </Card>
);
