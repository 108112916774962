import React, {useState, useEffect, useRef} from 'react';
import styled from 'styled-components';
import {Select, P, Link, Input, Radio, GridColumn} from 'notes';
import {SearchIcon} from 'Images';
import {_categories, _subcategory} from '../modules';

export const MobileFilter = ({
  options,
  open = false,
  setOpen,
  onChange,
  selected,
}) => {
  if (!!options.labels) {
    var categories = _categories(options.labels);
    var selectedCategory = categories.find(
      ({id}) => id === selected.categoryId
    );
    var mutatedFilters = _subcategory(options.filters);
  }

  const [category, setCategory] = useState(selectedCategory);
  const [filters, setFilters] = useState(mutatedFilters);
  const [subcategoryId, setSubcategoryId] = useState(selected.subcategoryId);
  const [filterValue, setFilterValue] = useState('');
  const filterRef = useRef({});

  const filterResults = value => {
    const allOptions = filters.filter(
      ({categoryId}) => categoryId === category.id
    );
    const filteredOptions = allOptions.filter(
      item => item.text.toLowerCase().indexOf(value.toLowerCase()) > -1
    );
    return filteredOptions;
  };

  useEffect(() => {
    if (filterValue) {
      const filteredResults = filterResults(filterValue);
      setFilters(filteredResults);
    } else {
      setFilters(mutatedFilters);
    }
  }, [filterValue]);

  const handleChange = value => {
    setCategory(value);
    if (value.id === 1) {
      setSubcategoryId(1);
    }
  };

  useEffect(() => {
    if (!!mutatedFilters) {
      const allOptions = mutatedFilters.filter(
        ({categoryId}) => categoryId === category.id
      );
      setFilters(!!allOptions ? allOptions : []);
    }
  }, [category]);

  const applyChanges = () => {
    setOpen(false);
    onChange({categoryId: category.id, subcategoryId: subcategoryId});
    if (subcategoryId === selected.subcategoryId) {
      setCategory(selectedCategory);
    }
  };

  const captureRef = (ref, item) => {
    filterRef.current[item.id] = ref;
  };

  useEffect(() => {
    if (!!open && !!selected.subcategoryId && selected.subcategoryId !== 1) {
      const selectedItem = filters.find(
        ({id}) => id === selected.subcategoryId
      );
      handleScroll(selectedItem);
    }
  }, [open]);

  const isInViewport = elem => {
    var bounding = elem.getBoundingClientRect();
    return (
      bounding.top >= 0 &&
      bounding.left >= 0 &&
      bounding.bottom <=
        (window.innerHeight || document.documentElement.clientHeight) &&
      bounding.right <=
        (window.innerWidth || document.documentElement.clientWidth)
    );
  };

  const handleScroll = item => {
    const focusRef = filterRef.current[item.id];
    var inView = isInViewport(focusRef);
    if (focusRef && !inView) {
      focusRef.scrollIntoView({
        behavior: 'smooth',
      });
    }
  };

  return open ? (
    <Wrapper>
      <FilterType noShrink>
        <SaveButton onClick={() => applyChanges()}>Done</SaveButton>
        <TypeLabel>Filters</TypeLabel>
        <Select
          options={categories}
          selected={category}
          onChange={handleChange}
        />
      </FilterType>
      <Scroll>
        {category.id === 700 && (
          <FilterSearch>
            <Input
              leftIcon={<SearchIcon />}
              onChange={setFilterValue}
              value={filterValue}
              placeholder="Search for a item by venue name or location..."
            />
          </FilterSearch>
        )}
        <FilterItems>
          {!!filters &&
            filters.map(item => (
              <RadioField
                key={item.id}
                checked={item.id === subcategoryId}
                onClick={() => setSubcategoryId(item.id)}
                title={item.text}
                ref={ref => captureRef(ref, item)}
              >
                {item.subtext}
              </RadioField>
            ))}
        </FilterItems>
      </Scroll>
    </Wrapper>
  ) : (
    ''
  );
};

const Scroll = styled(GridColumn)`
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  width: 100%;
`;

const RadioField = styled(Radio)`
  border-bottom: 1px solid ${props => props.theme.colors.borderLight};
  margin: 0;
  padding-top: 16px;
  padding-bottom: 16px;
  outline: 0;
  & > input {
    top: -2px;
  }
`;

const TypeLabel = styled(P)`
  font-weight: 700;
  margin-bottom: 17px;
  text-align: center;
`;

const SaveButton = styled(Link)`
  font-weight: 600;
  position: absolute;
  right: 24px;
`;

const Wrapper = styled(GridColumn)`
  background-color: #fff;
  height: 100%;
  position: fixed;
  width: 100%;
  z-index: 200;
`;

const FilterType = styled(GridColumn)`
  background-color: #fafafa;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.48);
  height: 107px;
  padding: 17px 24px 12px 24px;
  position: relative;
`;

const FilterSearch = styled(GridColumn)`
  padding: 24px;
  width: 100%;
`;

const FilterItems = styled(FilterSearch)`
  padding-top: 0;
`;
