export const _buildSection = (type, data) => {
  const getHeader = type => {
    switch (type) {
      case 2:
        return {
          title: 'What Your Fans Want to Hear',
          description:
            'Below are the most requested songs your fans would like to hear and their corresponding albums.',
        };
      case 3:
        return {title: 'Concert Attendance', description: ''};
      case 4:
        return {
          title: 'Audience Breakdowns',
          description:
            'Below is a geographic and demographic breakdown of the fans who submitted a set list.',
        };
      case 8:
        return {
          title: 'Collection Insights',
          description:
            'Here are the types of devices your fans used to submit their set list.',
        };
      default:
        return '';
    }
  };

  const simpleArray = {
    content: {
      type: type,
      data: data,
    },
  };

  const header = getHeader(type);
  const headerArray = {
    header,
    content: {
      type: type,
      data: data,
    },
  };

  return [5, 6].includes(type) ? simpleArray : headerArray;
};
