import {_singleCriteriaGraph} from './_SingleCriteriaGraph';
import {_requestedSongsAlbums, _songs, _albums} from './_RequestedSongs';
// import { _concertAttendance } from "./_ConcertAttendance";
import {_summary} from './_Summary';
import {_heatmap} from './_Heatmap';
import {_buildSection} from './_BuildSection';

export const dataFilter = (data, type) => {
  const filterMetrics = (data, filter) => {
    const newArray = data.filter(({metric_section}) =>
      metric_section.includes(filter)
    );
    return newArray;
  };

  var songs = _songs(
    'Top Requested Songs',
    filterMetrics(data.metrics, 'top_tracks')
  );

  var albums = _albums(
    'Albums',
    filterMetrics(data.metrics, 'album_pie_chart')
  );

  var overallMetrics = _summary(
    1,
    filterMetrics(data.metrics, 'overall_metrics')
  );
  var requestedSongs = _requestedSongsAlbums(songs, albums);
  var heatmap = _heatmap('Fan Locations', data.map, type);
  var genderChart = _singleCriteriaGraph(
    1,
    'Gender',
    filterMetrics(data.metrics, 'gender_chart')
  );
  var generationChart = _singleCriteriaGraph(
    2,
    'Generation',
    filterMetrics(data.metrics, 'generation_chart')
  );
  var collectionInsights = _summary(
    2,
    filterMetrics(data.metrics, 'collection_insights')
  );

  const sections = [
    {type: 6, object: overallMetrics},
    {type: 2, object: requestedSongs},
    {type: 4, object: heatmap},
    {type: 5, object: genderChart},
    {type: 5, object: generationChart},
    {type: 8, object: collectionInsights},
  ];

  const checkObject = sections.filter(({object}) => !!object);

  const builtObjects = checkObject.map(({type, object}) =>
    _buildSection(type, object)
  );

  return builtObjects;
};
