import React from 'react';
import styled from 'styled-components';
import {BannerImage} from 'Data';
import {H1} from 'notes';

const Container = styled.div`
  height: 100%;
  width: 312px;
  box-shadow: 0 0px 4px rgba(0, 0, 0, 0.5);
  padding: 36px;
`;

const BandImage = styled.img`
  min-width: 104px;
  height: 104px;
  min-height: 104px;
`;

const ImageMask = styled.div`
  width: 104px;
  height: 104px;
  border-radius: 50%;
  overflow: hidden;
  margin-bottom: 36px;
`;

export const LeftBar = () => (
  <Container>
    <ImageMask>
      <BandImage src={BannerImage} />
    </ImageMask>
    <H1>Rascal Flatts</H1>
  </Container>
);
