import React, {useState} from 'react';
// import { SurveyContext } from "Providers/SurveyProvider";
import styled from 'styled-components';
import {GridRow} from 'notes';

export const LanguageSelector = ({dark = false}) => {
  const [language, setLanguage] = useState('en');
  // const {
  //   state: { locale },
  //   setLanguage
  // } = useContext(SurveyContext);
  var locale = language;

  const render = () => {
    return (
      <LanguageSelectorContainer dark={dark}>
        <Content>
          <SelectorLabel dark={dark}>Language</SelectorLabel>
          <GridSpacing>
            {locale === 'en' ? (
              <Selected>English</Selected>
            ) : (
              <Unselected
                dark={dark}
                onClick={() => setLanguage('en')}
                data-javelin-name={`change-language`}
                data-javelin-meta={`en`}
              >
                English
              </Unselected>
            )}
          </GridSpacing>
          <GridSpacing>
            {locale === 'es' ? (
              <Selected>Español</Selected>
            ) : (
              <Unselected
                dark={dark}
                onClick={() => setLanguage('es')}
                data-javelin-name={`change-language`}
                data-javelin-meta={`es`}
              >
                Español
              </Unselected>
            )}
          </GridSpacing>
        </Content>
      </LanguageSelectorContainer>
    );
  };

  return render();
};

const LanguageSelectorContainer = styled(GridRow)`
  background-color: ${props =>
    props.dark ? props.theme.colors.text : '#FFFFFF'};
  box-sizing: border-box;
  height: 40px;
  padding: 0 24px;
  width: 100%;
`;

const Content = styled(GridRow)`
  align-items: center;
  justify-content: flex-start;
  margin: 0 auto;
  width: 100%;
  max-width: ${props => props.theme.grid.content}px;
`;

const GridSpacing = styled(GridRow)`
  margin-left: 24px;
`;

const Selected = styled.a`
  color: ${props => props.theme.colors.disabledText};
  cursor: default;
  ${props => props.theme.fonts.default};
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  padding: 4px 0;
`;

const SelectorLabel = styled(Selected).attrs({as: 'div'})`
  color: ${props => props.theme.colors.inputPlaceholderText};
`;

const Unselected = styled(Selected)`
  color: ${props => (props.dark ? '#FFFFFF' : props.theme.colors.action)};
  cursor: pointer;
`;
