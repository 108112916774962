import React, {useContext, useReducer} from 'react';
import styled from 'styled-components';
import {
  H4,
  H3,
  P,
  ButtonSecondary,
  ButtonPrimary,
  Select,
  GridRow,
  GridColumn,
} from 'notes';
import {LoaderInline} from 'Components';
import {CloseIcon} from 'Images';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import {ReportContext} from 'Providers';
import {reducer} from 'Modules';
import {ExportCard} from './card';
import {useFirebaseContext} from 'hooks';

const selectOptions = [
  {text: 'Excel Spreadsheet (XLS)', id: 2},
  {text: 'CSV File', id: 1},
];

const initialButtonState = {
  downloadSummary: false,
  downloadContacts: false,
  downloadSongs: false,
};

const initialSelectState = {
  contactSheet: {
    text: 'Excel Spreadsheet (XLS)',
    id: 2,
  },
  concertsAndSongs: {
    text: 'Excel Spreadsheet (XLS)',
    id: 2,
  },
};

export const ExportModal = ({filterName, open = false, setOpen}) => {
  const {artist, user} = useContext(ReportContext);
  const [buttonState, setButtonState] = useReducer(reducer, initialButtonState);
  const [selectState, setSelectState] = useReducer(reducer, initialSelectState);

  const {callable} = useFirebaseContext();

  const createPDF = () => {
    setButtonState({downloadSummary: true});
    const input = document.getElementById('page-print');
    const options = {
      imageTimeout: 0,
      scale: 2,
      y: 0,
      useCORS: true,
    };
    html2canvas(input, options).then(function(canvas) {
      const imgData = canvas.toDataURL('image/png');

      const pdf = new jsPDF({
        unit: 'px',
        format: [input.clientWidth, input.clientHeight],
      });

      var width = pdf.internal.pageSize.getWidth();
      var height = pdf.internal.pageSize.getHeight();

      pdf.addImage(imgData, 'PNG', 0, 0, width, height, null, 'MEDIUM');
      pdf.save(`Set_the_Set-${filterName.replace(/\s+/g, '_')}-Results.pdf`);
      setButtonState({downloadSummary: false});
    });
  };

  const handleDownload = async (reportType, fileType) => {
    var setLoadingValue =
      reportType === 1 ? {downloadContacts: true} : {downloadSongs: true};
    setButtonState(setLoadingValue);
    try {
      const {data} = await callable('report-download', {
        collectorId: artist.collector_id,
        token: user,
        reportType,
        fileType,
      });
      if (!!data.file) {
        window.location = data.file;
      }
    } catch (err) {
      console.log(err);
    }
    var setUnloadingValue =
      reportType === 1 ? {downloadContacts: false} : {downloadSongs: false};
    setButtonState(setUnloadingValue);
  };

  return open ? (
    <Wrapper>
      <Header>
        <Title>Export Results</Title>
        <Close
          onClick={() => {
            setOpen(false);
          }}
        />
      </Header>
      <Content>
        <H3>Export Fans Responses</H3>
        <HeaderDescription>
          Choose the type of report you would like below. Analysis of your fans'
          responses can either be exported as a report (with charts and graphs)
          or in tabular form for use in a spreadsheet, fan management platform
          or a database.
        </HeaderDescription>
        <CardOptions>
          <ExportCard
            title="Summary View"
            description="Export the currently selected filter of your responses as a PDF document for
                sharing and viewing offline."
            type={1}
          >
            <Button
              disabled={buttonState.downloadSummary}
              onClick={() => createPDF()}
            >
              {buttonState.downloadSummary ? (
                <LoaderInline />
              ) : (
                'Download Summary'
              )}
            </Button>
          </ExportCard>
          <ExportCard
            title="Contact Sheet"
            description="Export your fans’ contact information (name, gender, age,
                location, email, phone number, etc.) for use in a mailing list
                or other applications."
            type={2}
          >
            <Select
              style={{cursor: 'default'}}
              selected={selectState.contactSheet}
              onChange={value => setSelectState({contactSheet: value})}
              options={selectOptions}
            />
            <Button
              disabled={buttonState.downloadContacts}
              onClick={() => handleDownload(1, selectState.contactSheet.id)}
            >
              {buttonState.downloadContacts ? (
                <LoaderInline />
              ) : (
                'Download Contacts'
              )}
            </Button>
          </ExportCard>
          <ExportCard
            title="Concerts &amp; Songs"
            description="Export your fans’ selections as a spreadsheet to view their
                requested songs, concert attendance and contact information."
            type={3}
          >
            <Select
              style={{cursor: 'default'}}
              selected={selectState.concertsAndSongs}
              onChange={value => setSelectState({concertsAndSongs: value})}
              options={selectOptions}
            />
            <Button
              disabled={buttonState.downloadSongs}
              onClick={() => handleDownload(2, selectState.concertsAndSongs.id)}
            >
              {buttonState.downloadSongs ? (
                <LoaderInline />
              ) : (
                'Download Selections'
              )}
            </Button>
          </ExportCard>
        </CardOptions>
      </Content>
      <DoneButton
        onClick={() => {
          setOpen(false);
        }}
      >
        Done
      </DoneButton>
    </Wrapper>
  ) : (
    ''
  );
};

const Wrapper = styled(GridColumn)`
  background-color: #fff;
  box-sizing: border-box;
  height: 100%;
  max-width: 1304px;
  overflow-y: scroll;
  position: fixed;
  width: 100%;
  z-index: 200;
`;

const Header = styled(GridRow)`
  align-items: center;
  background-color: #fff;
  border-bottom: 1px solid ${props => props.theme.colors.disabledText};
  box-sizing: border-box;
  flex-shrink: 0;
  height: 48px;
  justify-content: space-between;
  padding: 0 40px;
  position: sticky;
  top: 0;
  width: 100%;
  z-index: 201;
`;

const HeaderDescription = styled(P)`
  margin-top: 8px;
`;

const Title = styled(H4)`
  font-weight: 700;
`;

const Close = styled(CloseIcon)`
  cursor: pointer;
`;

const Content = styled(GridColumn)`
  box-sizing: content-box;
  margin: 0 auto;
  max-width: 1016px;
  padding: 40px 24px;
`;

const CardOptions = styled(GridRow)`
  margin-top: 56px;
`;

const Button = styled(ButtonPrimary)`
  margin-top: 16px;
`;

const DoneButton = styled(ButtonSecondary)`
  align-self: center;
  margin: 32px 0;
`;
