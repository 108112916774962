export const _summary = (type, values) => {
  const newArray = values
    .map(({primary_label, primary_value, tertiary_label}) => ({
      title: primary_label,
      // description: "17 Responses this week",
      value: primary_value,
      type: tertiary_label ? 3 : type,
      tooltip: null,
    }))
    .sort()
    .reverse();
  if (newArray.length < 2 || newArray[0].type === 2) {
    return null;
  }
  return newArray;
};
