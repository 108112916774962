import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import smoothscroll from 'smoothscroll-polyfill';
import {ArrowUp} from 'Images';

export const ScrollToTop = () => {
  const [scrollPosition, setScrollPosition] = useState(0);
  const [scrollButton, setScrollButton] = useState(true);

  smoothscroll.polyfill();

  const scrollToTop = () => {
    window.scroll({top: 0, left: 0, behavior: 'smooth'});
  };

  useEffect(() => {
    window.addEventListener('scroll', function(e) {
      setScrollPosition(window.pageYOffset);
    });
    if (scrollPosition > 150) {
      setScrollButton(true);
    } else {
      setScrollButton(false);
    }
  }, [scrollPosition]);

  return (
    <ScrollButtonContainer className="print-hide" visible={scrollButton}>
      <ScrollButton onClick={scrollToTop}>
        <ArrowUp />
      </ScrollButton>
    </ScrollButtonContainer>
  );
};

const ScrollButtonContainer = styled.div`
  align-self: flex-end;
  display: block;
  height: 0;
  padding-right: 15px;
  position: sticky;
  top: 92vh;
  transition: all ease 0.3s;
  opacity: ${props => (props.visible ? 1 : 0)};
  visibility: ${props => (props.visible ? 'visible' : 'hidden')};
  z-index: 100;
  @media only screen and ${props => props.theme.media.large} {
    padding-right: 40px;
  }
  @media only screen and ${props => props.theme.media.small} {
    top: 78vh;
  }
`;

const ScrollButton = styled.div`
  align-items: center;
  cursor: pointer;
  display: flex;
  background-color: rgba(0, 0, 0, 0.72);
  border-radius: 4px;
  box-sizing: border-box;
  height: 40px;
  justify-content: center;
  width: 40px;
`;
