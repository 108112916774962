const displayDateOptions = {
  month: 'short',
  day: 'numeric',
  year: 'numeric',
};

export const formatDate = (date, options = displayDateOptions) => {
  const arr = date && date.split(/[- :]/);
  const displayDate = new Date(
    arr[0],
    arr[1] - 1,
    arr[2],
    arr[3],
    arr[4],
    arr[5]
  );
  return displayDate.toLocaleDateString('en-us', options);
};
