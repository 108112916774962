import React from 'react';
import {useHistory} from 'react-router-dom';
import styled from 'styled-components';
import {withDefaultMedia, H3, Subheader, GridColumn, GridRow} from 'notes';
import {LargeLogo, ArrowLeftIcon} from 'Images';
import {Footer, ScrollToTop} from 'Components';

export const Layout = withDefaultMedia(({matches, children, title = false}) => {
  let history = useHistory();

  return (
    <Container isLogin={!title}>
      <Page>
        <LogoPosition>
          <LargeLogo />
        </LogoPosition>
        {!!title && <ScrollToTop />}
        <Content isLogin={!title}>
          {!!title && (
            <Header>
              <ReturnLink onClick={() => history.goBack()}>
                <Arrow />
                Back
              </ReturnLink>
              <PageTitle>{title}</PageTitle>
            </Header>
          )}
          {children}
        </Content>
      </Page>
      <FooterPosition dark={matches.small ? true : false} />
    </Container>
  );
});

const Container = styled(GridColumn)`
  min-height: ${props => (props.isLogin ? '786px' : '100vh')};
  height: ${props => (props.isLogin ? '100vh' : 'auto')};
  position: relative;
`;

const Page = styled(GridColumn)`
  align-items: center;
  background-color: #fafafa;
  color: #000;
  height: 100%;
  min-height: 440px;
  padding: 114px 0;
  @media only screen and ${props => props.theme.media.small} {
    background-color: #ffffff;
    padding-top: 0;
  }
`;

const Content = styled(GridColumn)`
  ${props => props.isLogin && 'align-items: center'};
  margin: 0 auto;
  max-width: 1016px;
  padding: 0 24px;
  width: 100%;
`;

const LogoPosition = styled.div`
  position: absolute;
  left: 40px;
  top: 40px;
  @media only screen and ${props => props.theme.media.small} {
    display: flex;
    justify-content: center;
    margin-top: 40px;
    position: relative;
    left: 0;
    top: 0;
    width: 100%;
  }
`;

const FooterPosition = styled(Footer)`
  flex-shrink: 0;
  padding: 0;
`;

const PageTitle = styled(H3)``;

export const ReturnLink = styled(Subheader)`
  cursor: pointer;
  font-size: 17px;
  position: absolute;
  left: 0;
  &:hover {
    text-decoration: underline;
  }
  @media only screen and ${props => props.theme.media.small} {
    margin: 24px 0 32px 0;
    position: relative;
    width: 100%;
  }
`;

export const Arrow = styled(ArrowLeftIcon)`
  margin-right: 12px;
  margin-bottom: -2px;
  path {
    fill: #a6aeb2;
  }
`;

export const Header = styled(GridRow)`
  justify-content: center;
  margin-bottom: 44px;
  position: relative;
  width: 100%;
  @media only screen and ${props => props.theme.media.small} {
    flex-direction: column;
    margin-bottom: 24px;
  }
`;
