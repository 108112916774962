export const _singleCriteriaGraph = (order, label, values) => {
  const valueList = values
    .map(({primary_label, primary_value}, index) => ({
      id: ++index,
      label: primary_label,
      value: Number(primary_value),
    }))
    .filter(({value}) => value > 5);

  const orderedArray =
    order === 1
      ? valueList.sort((a, b) => b.value - a.value)
      : valueList.sort();
  const newArray = {
    data: orderedArray,
    label: label,
  };
  return values.length < 3 ? null : newArray;
};
