import React, {createContext} from 'react';

import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/functions';
import 'firebase/firestore';

/**
 * @typedef {}
 */

const config = {
  apiKey: process.env.REACT_APP_FIREBASE_APIKEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTHDOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASEURL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECTID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGEBUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGINGSENDERID,
  appId: process.env.REACT_APP_FIREBASE_APPID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENTID,
};

//Firebase.init({ firebase });
const app = firebase.initializeApp(config);

export const FirebaseContext = createContext(null);

/**
 * Call a Cloud Function named `name` with parameters `data`.
 * This method will safely call the Cloud Function, if the function
 * call fails, the error will be caught and returned in the result.
 *
 * @typedef {object} CallableResult
 * @property {any=} data Data returned from the function
 * @property {firebase.functions.HttpsError=} error Any error thrown by the function
 *
 * @param {string} name Name of HTTPS Callable function
 * @param {any} data Data to pass to function
 * @returns {Promise<CallableResult>}
 */
export const callable = async (name, data) => {
  const func = app.functions().httpsCallable(name);

  try {
    return await func(data);
  } catch (error) {
    return {error};
  }
};

export const FirebaseProvider = ({children}) => {
  return (
    <FirebaseContext.Provider value={{firebase: app, callable}}>
      {children}
    </FirebaseContext.Provider>
  );
};
