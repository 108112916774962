import React from 'react';
import {BrowserRouter as Router} from 'react-router-dom';
import {ThemeProvider} from 'styled-components';
import {MAXTheme} from 'notes';
import {ReportProvider, FirebaseProvider} from 'Providers';

import {Routes} from './Routes';

const customTheme = {
  ...MAXTheme,
  dark: false,
  darkDefault: '#FFFFFF',
  lightDefault: '#222222',
  lightFooterBorder: '#D0D3D5',
  darkFooterBorder: '#535658',
  colors: {
    ...MAXTheme.colors,
    action: '#39A4A1',
    actionLight: '#EDF7F7',
    buttonPrimaryBgHover: '#105568',
    linkHover: '#105568',
    removeRed: '#E45C52',
    errorMessageBorder: '#EDB8B4',
  },
  media: {
    small: '(max-width: 767px)',
    medium: '(min-width: 768px) and (max-width: 1063px)',
    large: '(min-width: 1064px)',
  },
  grid: {
    margin: 40,
    columns: 12,
    gutter: 24,
    content: 1016,
    wrap: 1304,
  },
};

function App() {
  return (
    <Router>
      <ThemeProvider theme={customTheme}>
        <FirebaseProvider>
          <ReportProvider>
            <Routes />
          </ReportProvider>
        </FirebaseProvider>
      </ThemeProvider>
    </Router>
  );
}

export default App;
