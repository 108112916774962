import React, {Fragment} from 'react';
import styled from 'styled-components';
import {SectionHeader} from 'Components';
import {RequestedSongs} from './RequestedSongs';
import {ConcertAttendance} from './ConcertAttendance';
import {SingleCriteriaGraph} from './SingleCriteriaGraph';
import {StyledLabel} from './SingleCriteriaGraph';
import {Summary} from './Summary';
import {Collector} from 'Components';
import {HeatMap} from 'notes';

export const Section = ({header, content, options}) => {
  return (
    <SectionContainer size={content.type} options={!!options && options}>
      <ContentWrap>
        {!!header && <SectionHeader {...header} />}
        {(() => {
          switch (content.type) {
            case 2:
              return (
                <RequestedSongs
                  songs={content.data.songs}
                  albums={content.data.albums}
                />
              );
            case 3:
              return (
                <ConcertAttendance
                  label={content.data.label}
                  items={content.data.data}
                />
              );
            case 4:
              return (
                <Fragment>
                  <StyledLabel>{content.data.label}</StyledLabel>
                  <StyledHeatMap
                    center={content.data.center}
                    fitPoints={
                      content.data.center ? undefined : content.data.points
                    }
                    points={content.data.points}
                    zoom={content.data.zoom}
                  />
                </Fragment>
              );
            case 5:
              return (
                <SingleCriteriaGraph
                  label={content.data.label}
                  items={content.data.data}
                />
              );
            case 6:
              return <Summary items={content.data} />;
            case 7:
              return (
                <Collector
                  headers={content.data.headers}
                  items={content.data.rows}
                />
              );
            case 8:
              return <Summary items={content.data} />;
            default:
              return '';
          }
        })()}
      </ContentWrap>
    </SectionContainer>
  );
};

const SectionContainer = styled.div`
  background-color: ${props =>
    props.options.color === 'secondary' ? '#FAFAFA' : '#FFFFFF'};
  box-sizing: border-box;
  margin-top: ${props => (props.options.style === 'secondary' ? '44px' : 0)};
  padding-top: ${props =>
    props.options.style === 'secondary'
      ? '40px'
      : [5, 6, 7].includes(props.size)
      ? '28px'
      : '36px'};
  padding-bottom: ${props =>
    [5, 6, 7].includes(props.size) ? '28px' : '36px'};
  width: 100%;
  padding-left: 24px;
  padding-right: 24px;
  &:nth-last-of-type(2) {
    padding-bottom: 72px;
  }
  &:last-of-type {
    background-color: #fafafa;
    padding-bottom: 144px;
    padding-top: 40px;
  }
`;

export const ContentWrap = styled.div`
  margin: 0 auto;
  max-width: 1016px;
  width: 100%;
`;

const StyledHeatMap = styled(HeatMap)`
  @media only screen and ${props => props.theme.media.small} {
    height: 179px;
  }
`;
