import _ from 'lodash';

export const reducer = (state, payload) => {
  const keys = Object.keys(payload);

  if (_.isEqual(state, payload)) {
    return state;
  }

  if (_.isArray(payload)) {
    return payload;
  } else {
    for (let i = 0; i < keys.length; i++) {
      let key = keys[i];

      if (!_.isEqual(state[key], payload[key])) {
        return _.extend({}, state, payload);
      }
    }
  }
  return state;
};
