import React, {createContext, useState} from 'react';

export const ReportContext = createContext();

export const ReportConsumer = ReportContext.Consumer;

export const ReportProvider = ({children, ...props}) => {
  const [filters, setFilters] = useState([]);
  const [report, setReport] = useState([]);
  const [artist, setArtist] = useState({});
  const [user, setUser] = useState('');

  const value = {
    filters,
    setFilters,
    report,
    setReport,
    artist,
    setArtist,
    user,
    setUser,
  };

  return (
    <ReportContext.Provider value={value} {...props}>
      {children}
    </ReportContext.Provider>
  );
};
