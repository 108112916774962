const centerUSA = {
  lng: -70.699436,
  lat: 43.15405,
};

export const _heatmap = (label, values, type, zoom = 2) => {
  const points = values.map(({lat, lng, weight}) => ({
    lat: Number(lat),
    lng: Number(lng),
    size: Number(weight),
  }));

  const centerArray = {
    zoom: zoom,
    label: label,
    points: points,
    center: centerUSA,
  };

  const newArray = {
    zoom: zoom,
    label: label,
    points: points,
  };

  return type === 1 ? centerArray : newArray;
};
