import React, {useState, Fragment, useEffect, useContext} from 'react';
import {useHistory} from 'react-router-dom';
import styled from 'styled-components';
import {LoaderInline} from 'Components';
import {ReportContext} from 'Providers';
import {
  Subheader,
  Label,
  Input,
  ButtonPrimary,
  Link,
  Toolbox,
  H4,
  P,
  withDefaultMedia,
  GridRow,
} from 'notes';
import {KeyIcon, PrivacyHide, PrivacyShow} from 'Images';
import {dataFilter} from '../modules';
import {Layout} from '../';
import {useFirebaseContext} from 'hooks';

export const LoginLogic = ({match, matches}) => {

  let history = useHistory();
  const [password, setPassword] = useState('');
  const [error, setError] = useState({text: '', value: false});
  const [visibility, setVisibility] = useState(false);
  const [loading, setLoading] = useState(false);
  const [collectorId, setCollectorId] = useState('');

  const {setArtist, setReport, artist, setFilters, setUser} = useContext(
    ReportContext
  );

  const {callable} = useFirebaseContext();

  const slug = match.params.slug?.toLowerCase();

  useEffect(() => {
    getCollector();
  }, [setArtist, slug]);

  const getCollector = async () => {
    try {
      if (!slug) return;
      const {data} = await callable('campaign-getCampaign', {
        id: slug,
      });
      /*
      const snap = await firebase
        .firestore()
        .collection('sts_campaigns')
        .doc(slug.toLowerCase())
        .get();
      if (!snap || !snap.exists) throw Error('Invalid collector');
      const data = snap.data();
      */
      setCollectorId(data.collector_id);
      setArtist({
        ...data.artist,
        tour: data.setlist.tour.name,
      });
      var urlParams = new URLSearchParams(window.location.search);
      if (urlParams.has('p')) {
        const p = urlParams.get('p');
        setPassword(p);
        await login(p, data.collector_id || data.artist.collector_id);
      }
    } catch (err) {
      console.log(err);
      setError({
        text: `Error getting artist info. "${slug}" is invalid.`,
        value: true,
      });
      setLoading(false);
    }
  };

  const login = async (token, id) => {
    console.log('login', token, id);
    try {
      const collectorId = id || collectorId || artist.collector_id;
      const result = await callable('report-auth', {collectorId, token});
      const data = result?.data || {};
      if (!data?.metrics?.length) {
        setError({text: 'No report data is available.', value: true});
        setLoading(false);
        return;
      }
      setUser(token);
      setFilters({filters: data.filters, labels: data.labels});
      const newArray = {metrics: data.metrics, map: data.map};
      const filteredArray = dataFilter(newArray, 1);
      setReport(filteredArray);
      history.push(`/${slug}/${collectorId}`);
    } catch (err) {
      console.log(err);
      setError({text: 'Incorrect password provided.', value: true});
      setLoading(false);
    }
  };

  const handleSubmit = event => {
    setLoading(true);
    event.preventDefault();
    if (!!password) {
      login(password);
    } else {
      setError({text: 'Please provide a password.', value: true});
      setLoading(false);
    }
  };

  const handleChange = value => {
    setPassword(value);
    setError({text: '', value: false});
  };

  return (
    <Layout login={true}>
      {!!artist && (
        <Box>
          <Avatar
            image={
              !!artist.image
                ? artist.image
                : artist.images
                ? artist.images[0].url
                : ''
            }
          />
          <StyledSubheader>{artist.name}</StyledSubheader>
          <StyledForm onSubmit={handleSubmit}>
            <StyledLabel>Password</StyledLabel>
            <StyledInput
              error={error.value}
              style={{width: '100%'}}
              leftIcon={<StyledKeyIcon />}
              rightIcon={
                visibility ? (
                  <IconShow onClick={() => setVisibility(false)} />
                ) : (
                  <IconHide onClick={() => setVisibility(true)} />
                )
              }
              value={password}
              onChange={handleChange}
              type={visibility ? 'text' : 'password'}
              placeholder="Provided by Music Audience Exchange..."
            />
            {error.value && (
              <ErrorMessage>
                <ErrorIcon viewBox="0 0 16 16">
                  <path
                    d="M9.26.47A1 1 0 008.81 0a1 1 0 00-1.36.47l-7 14a1 1 0 00-.1.45 1 1 0 001 1h14a1.07 1.07 0 00.46-.11 1 1 0 00.45-1.36z"
                    transform="translate(-.36 .09)"
                    fill="#e45c52"
                  />
                  <path fill="#FFF" d="M7 5h2v5H7z" />
                  <circle fill="#FFF" cx="8" cy="13" r="1" />
                </ErrorIcon>
                {error.text}
              </ErrorMessage>
            )}
            <ButtonContainer>
              <Toolbox
                placement="top"
                simple
                trigger={props => (
                  <StyledLink href="#" {...props}>
                    I Need A Password
                  </StyledLink>
                )}
              >
                {({close}) => (
                  <Fragment>
                    <StyledH4>Don't Have or Lost Your Password?</StyledH4>
                    <P>
                      If you have not received your password from our Artist
                      Relations Team, feel free to give us a shout!{' '}
                      <ContactLink
                        target="_blank"
                        href="https://settheset.com/contact"
                      >
                        Click Here
                      </ContactLink>{' '}
                      to open a new window.
                    </P>
                    <LinkContainer xEnd>
                      <ToolboxLink onClick={close}>Got It</ToolboxLink>
                    </LinkContainer>
                  </Fragment>
                )}
              </Toolbox>
              <Button disabled={loading} type="submit">
                {loading ? <LoaderInline /> : 'Login'}
              </Button>
            </ButtonContainer>
          </StyledForm>
        </Box>
      )}
    </Layout>
  );
};

export const Login = withDefaultMedia(LoginLogic);

const Button = styled(ButtonPrimary)`
  order: 2;
  @media only screen and ${props => props.theme.media.small} {
    order: 1;
  }
`;

const ErrorIcon = styled.svg`
  height: 16px;
  margin-right: 12px;
  width: 16px;
`;

const ErrorMessage = styled(P)`
  align-items: center;
  color: #e45c52;
  display: flex;
  font-size: 15px;
  margin-top: 9px;
`;

const StyledForm = styled.form`
  width: 100%;
`;

const IconShow = styled(PrivacyShow)`
  margin-bottom: 2px;
  path {
    fill: #39a4a1;
  }
`;

const IconHide = styled(PrivacyHide)`
  margin-bottom: 2px;
  path {
    fill: ${props => props.theme.colors.inputPlaceholderText};
  }
`;

const ButtonContainer = styled(GridRow)`
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 56px;
  width: 100%;

  @media only screen and ${props => props.theme.media.small} {
    align-items: stretch;
    flex-direction: column;
    margin-top: 32px;
  }
`;

const ContactLink = styled(Link)`
  display: inline-block;
`;

const StyledLink = styled(Link).attrs({as: 'div'})`
  cursor: pointer;
  color: ${props => props.theme.colors.action};
  font-size: 12px;
  font-weight: 600;
  height: 18px;
  line-height: 18px;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
  order: 1;
  @media only screen and ${props => props.theme.media.small} {
    margin-top: 28px;
    order: 2;
    text-align: center;
  }
`;

const Avatar = styled.div`
  background-image: url(${props => props.image});
  background-position: center;
  background-size: cover;
  border-radius: 40px;
  display: flex;
  flex-shrink: 0;
  height: 80px;
  margin-bottom: 24px;
  width: 80px;
`;

const StyledSubheader = styled(Subheader)`
  margin-bottom: 40px;
`;

const StyledLabel = styled(Label)`
  margin-bottom: 2px;
  width: 100%;
`;

const StyledInput = styled(Input)`
  width: 100%;
  &:focus {
    border: 1px solid #a7dbda;
    box-shadow: 0 0 4px 0 #a7dbda;
  }
`;

const StyledKeyIcon = styled(KeyIcon)`
  margin-bottom: 6px;
  path {
    fill: ${props => props.theme.colors.inputPlaceholderText};
  }
`;

const Box = styled.div`
  align-items: center;
  display: flex;
  background-color: #ffffff;
  box-sizing: border-box;
  box-shadow: 0 8px 8px 0 rgba(0, 0, 0, 0.24), 0 0 8px 0 rgba(0, 0, 0, 0.32);
  flex-direction: column;
  justify-content: flex-start;
  padding: 32px 24px;
  width: 440px;

  @media only screen and ${props => props.theme.media.small} {
    box-shadow: none;
    padding: 0;
    padding-top: 56px;
    max-width: 440px;
    width: 100%;
  }
`;

const StyledH4 = styled(H4)`
  margin-bottom: 8px;
`;

const LinkContainer = styled(GridRow)`
  margin-top: 24px;
  width: 100%;
`;

const ToolboxLink = styled(Link)`
  font-weight: 600;
`;
