import React from 'react';
import styled from 'styled-components';
import {FlexBox, H2} from 'notes';
const Container = styled(FlexBox)`
  position: relative;
  width: 1304px;
  height: 382px;
  overflow: hidden;
`;
const BGImg = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;
const Header = styled(H2)`
  position: absolute;
  bottom: 0;
  padding: 40px;
  color: ${props => props.theme.colors.white};
`;
const Gradient = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 288px;
  background: linear-gradient(0deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 100%);
`;
export const ImageBanner = ({text, img}) => (
  <Container>
    <BGImg src={img} />
    <Gradient />
    <Header>{text}</Header>
  </Container>
);
