import React, {useState, Fragment} from 'react';
import styled from 'styled-components';
import {
  DonutChart,
  Legend,
  Label,
  withDefaultMedia,
  GridRow,
  GridColumn,
} from 'notes';

export const SingleCriteriaGraphLogic = ({matches, label, items}) => {
  const [songItem, setItem] = useState({});

  const handleHover = data => {
    setItem({id: data.id});
  };
  return (
    <Fragment>
      <StyledLabel>{label}</StyledLabel>
      <Row mobile={!matches.large} columns={matches.large ? 10 : 4}>
        <LegendContainer
          stretch
          mobile={!matches.large}
          columns={matches.large ? 6 : 3}
          columnRight={matches.large ? 1 : 0}
        >
          <LegendWidth
            direction="row"
            setRelatedHover={songItem}
            items={items}
            onHover={handleHover}
          />
        </LegendContainer>
        <ChartContainer mobile={!matches.large} columns={matches.small ? 4 : 3}>
          <Chart
            direction={matches.large ? 'row' : 'column'}
            data={items}
            setRelatedHover={songItem}
            title="Spend Distribution by Product"
            onHover={handleHover}
            width={matches.small ? 242 : 288}
          />
        </ChartContainer>
      </Row>
    </Fragment>
  );
};

export const SingleCriteriaGraph = withDefaultMedia(SingleCriteriaGraphLogic);

const Row = styled(GridRow)`
  @media only screen and ${props => props.theme.media.small} {
    flex-direction: column;
  }
  @media only screen and ${props => props.theme.media.medium} {
    justify-content: space-between;
  }
`;

const LegendContainer = styled(GridColumn)`
  order: 1;
  @media only screen and ${props => props.theme.media.medium} {
    margin-right: 44px;
  }
  @media only screen and ${props => props.theme.media.small} {
    order: 2;
  }
`;

const ChartContainer = styled(GridColumn)`
  align-items: center;
  order: 2;
  @media only screen and ${props => props.theme.media.small} {
    order: 1;
  }
`;

const Chart = styled(DonutChart)`
  margin-bottom: 32px;
  @media only screen and ${props => props.theme.media.large} {
    margin-bottom: 0;
  }
`;

const LegendWidth = styled(Legend)`
  width: 100%;
`;

export const StyledLabel = styled(Label)`
  font-size: 14px;
  font-weight: 600;
  justify-content: flex-start;
  margin-bottom: 24px;
  width: 100%;
`;
