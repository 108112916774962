import {numberWithCommas} from 'Modules';

export const _categories = options => {
  var categories = options.map(({filter_type, filter_type_id}) => ({
    text: filter_type,
    id: filter_type_id,
  }));
  return categories;
};

export const _subcategory = (options, itemId) => {
  var allOptions = options.map(
    ({filter_type_id, filter_name, filter_id, filter_instance_count}) => ({
      categoryId: filter_type_id,
      id: filter_id,
      text: filter_name,
      subtext: `(${numberWithCommas(filter_instance_count)} responses)`,
    })
  );

  var filteredOptions = allOptions.filter(
    ({categoryId}) => categoryId === itemId
  );

  return !!itemId ? filteredOptions : allOptions;
};
