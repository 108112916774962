import styled from 'styled-components';

export const LoaderInline = styled.div`
  &,
  &:before,
  &:after {
    border-radius: 50%;
    width: 9px;
    height: 9px;
    animation-fill-mode: both;
    animation: load7 1.4s infinite ease-in-out;
  }
  & {
    color: #a6aeb2;
    font-size: 10px;
    margin: 7px auto;
    position: relative;
    text-indent: -9999em;
    transform: translateZ(0);
    animation-delay: -0.16s;
    top: -12px;
  }
  &:before,
  &:after {
    content: '';
    position: absolute;
    top: 0;
  }
  &:before {
    left: -15px;
    animation-delay: -0.32s;
  }
  &:after {
    left: 15px;
  }
  @keyframes load7 {
    0%,
    80%,
    100% {
      box-shadow: 0 12px 0 -12px;
    }
    40% {
      box-shadow: 0 12px 0 0;
    }
  }
`;
