import React from 'react';
import styled from 'styled-components';
import {FlexBox} from 'notes';
import {LeftBar} from './Components';

const Container = styled.div``;
const TopBar = styled.div`
  width: 100%;
  height: 48px;
  box-shadow: 0 0px 4px rgba(0, 0, 0, 0.5);
  z-index: 10;
  position: relative;
`;
const BodyContainer = styled(FlexBox)``;
const ContentContainer = styled.div``;

export const Menu = ({children}) => (
  <Container>
    <TopBar />
    <BodyContainer>
      <LeftBar />
      <ContentContainer>{children}</ContentContainer>
    </BodyContainer>
  </Container>
);
