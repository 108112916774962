import React from 'react';
import styled from 'styled-components';
import {Logo} from 'Components';
import {
  P,
  LinkReverse,
  Link,
  withDefaultMedia,
  GridRow,
  GridColumn,
} from 'notes';

export const LinkSwitch = ({children, dark, ...props}) => {
  if (dark) {
    return <LinkReverse {...props}>{children}</LinkReverse>;
  } else {
    return <Link {...props}>{children}</Link>;
  }
};

const FooterLogic = ({matches, dark = false, ...props}) => {
  return (
    <FooterContainer dark={dark} {...props}>
      <SvgContainer dark={dark} />
      <ContentContainer dark={dark}>
        <Content>
          <GridColumn columns={matches.small ? 4 : 2}>
            <Logo dark={dark} />
            <Copyright>© 2020 Set The Set, Inc</Copyright>
          </GridColumn>
          <LinksContainer stretch>
            <LinkContainer row columns={matches.small ? 2 : 0}>
              <Links href="/privacy" dark={dark}>
                Privacy Policy
              </Links>
            </LinkContainer>
            <LinkContainer row columns={matches.small ? 2 : 0}>
              <Links href="/terms" dark={dark}>
                Terms of Use
              </Links>
            </LinkContainer>
            {/* <LinkContainer row columns={matches.small ? 2 : 0}>
              <Links dark={dark}>Artist Policy</Links>
            </LinkContainer> */}
            <LinkContainer row columns={matches.small ? 2 : 0}>
              <Links
                target="_blank"
                href="https://settheset.com/contact"
                dark={dark}
              >
                Contact Us
              </Links>
            </LinkContainer>
          </LinksContainer>
        </Content>
      </ContentContainer>
      {/* <LanguageSelector dark={dark} /> */}
      <LanguageSelectorPlaceholder dark={dark} />
    </FooterContainer>
  );
};

export const Footer = withDefaultMedia(FooterLogic);

const LanguageSelectorPlaceholder = styled(GridRow)`
  background-color: ${props =>
    props.dark ? props.theme.colors.text : '#FFFFFF'};
  box-sizing: border-box;
  height: 40px;
  padding: 0 24px;
  width: 100%;
`;

const FooterContainer = styled.div`
  align-items: center;
  background-color: transparent;
  display: flex;
  flex-direction: column;
  margin-top: -48px;
  overflow: hidden;

  @media only screen and ${props => props.theme.media.small} {
    margin-top: -29px;
  }
  @media only screen and ${props => props.theme.media.large} {
    z-index: 1;
  }
`;

const SvgContainer = styled.div`
  ${props =>
    props.dark
      ? `background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M1304 48v110H0V48C112 16 233.333 0 364 0c196 0 300 48 496 48 130.667 0 278.667-16 444-48v48z' fill-rule='evenodd' /%3e%3c/svg%3e")`
      : `background-image: url("data:image/svg+xml,%3Csvg width='1304' height='48' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M364 0c196 0 300 48 496 48 130.667 0 278.667-16 444-48v48H0C112 16 233.333 0 364 0z' fill='%23FFF' fill-rule='evenodd'/%3E%3C/svg%3E");`};
  height: 48px;
  background-position: center bottom;
  background-repeat: no-repeat;
  display: block;
  margin-bottom: -1px;
  width: 100%;

  @media only screen and ${props => props.theme.media.small} {
    background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg width='767px' height='29px' viewBox='0 0 767 29' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3e%3cpath d='M214.101227,0 C329.386503,0 390.558282,28.2331288 505.843558,28.2331288 C582.700409,28.2331288 669.752556,18.8220859 767,0 L767,28.2331288 L0,28.2331288 C65.8773006,9.41104294 137.244376,0 214.101227,0 Z'%3e%3c/path%3e%3c/svg%3e");
    height: 29px;
  }
  @media only screen and (max-width: 375px) {
    background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3e%3cpath fill='%23000' d='M0,16 L376,16 L376,204 L0,204 L0,16 Z M108,0 C164.515337,0 191.484663,16 248,16 C285.676892,16 328.343558,10.6666667 376,0 L376,16 L0,16 C34.3231084,5.33333333 70.3231084,0 108,0 Z'%3e%3c/path%3e%3c/svg%3e");
    height: 16px;
  }
`;

const ContentContainer = styled.div`
  align-items: center;
  background-color: ${props => (props.dark ? '#000000' : '#FFFFFF')};
  border-bottom: ${props =>
    props.dark ? 'none' : `1px solid ${props.theme.colors.borderLight}`};
  box-sizing: border-box;
  display: flex;
  min-height: 110px;
  padding: 24px;
  padding-bottom: 6px;
  width: 100%;
  @media only screen and ${props => props.theme.media.large} {
    padding-top: 0;
    padding-bottom: 0;
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  max-width: 1016px;
  width: 100%;
  @media only screen and ${props => props.theme.media.medium} {
    align-items: flex-end;
    flex-direction: row;
  }
  @media only screen and ${props => props.theme.media.large} {
    align-items: flex-end;
    flex-direction: row;
    height: 46px;
  }
`;

const Links = styled(LinkSwitch)`
  font-weight: 600;
  font-size: 12px;
  text-decoration: none;
  & + & {
    margin-left: 24px;
  }
`;

const LinksContainer = styled(GridRow)`
  justify-content: flex-start;
  margin-top: 20px;
  @media only screen and ${props => props.theme.media.small} {
    flex-wrap: wrap;
  }
  @media only screen and ${props => props.theme.media.medium} {
    flex-wrap: nowrap;
    justify-content: flex-end;
    margin-top: 0;
  }
  @media only screen and ${props => props.theme.media.large} {
    flex-wrap: nowrap;
    justify-content: flex-end;
    margin-top: 0;
  }
`;

const LinkContainer = styled(GridColumn)`
  margin-left: 0;
  margin-bottom: 18px;
  @media only screen and ${props => props.theme.media.medium} {
    margin-bottom: 0;
    & + & {
      margin-left: 24px;
    }
  }
  @media only screen and ${props => props.theme.media.large} {
    & + & {
      margin-left: 24px;
    }
    margin-bottom: 0;
  }
`;

const Copyright = styled(P)`
  color: ${props => props.theme.colors.disabledText};
  font-size: 12px;
`;
