import React, {useState, Fragment} from 'react';
import styled from 'styled-components';
import {H3, P, Link, withDefaultMedia} from 'notes';

export const SectionHeaderLogic = ({matches, title, description}) => {
  const [expand, setExpand] = useState(false);
  return (
    <Container>
      <Title>{title}</Title>
      {matches.large || description.length < 154 ? (
        <P>{description}</P>
      ) : (
        <Fragment>
          <P>
            {description.slice(0, 154)}
            <ExpandText active={expand}> {description.slice(155)}</ExpandText>
            {expand ? ' ' : '... '}
            <ExpandLink onClick={() => setExpand(!expand)}>
              {expand ? 'See Less' : 'See More'}
            </ExpandLink>
          </P>
        </Fragment>
      )}
    </Container>
  );
};

export const SectionHeader = withDefaultMedia(SectionHeaderLogic);

const Container = styled.div`
  margin-bottom: ${props => props.theme.grid.margin}px;
`;

const Title = styled(H3)`
  margin-bottom: 6px;
`;

const ExpandText = styled.span`
  display: ${props => (props.active ? 'inline' : 'none')};
`;

const ExpandLink = styled(Link)`
  display: inline-block;
`;
