export const _songs = (label, values) => {
  const songList = values
    .map(
      ({
        primary_label,
        metric_name,
        primary_value,
        secondary_label,
        secondary_value,
      }) => ({
        albumId: secondary_label,
        id: metric_name,
        label: primary_label,
        value: Number(primary_value),
        percentage: Number(secondary_value),
      })
    )
    .sort((a, b) => parseFloat(b.value) - parseFloat(a.value));
  const newArray = {
    data: songList,
    label: label,
  };
  return newArray;
};

export const _albums = (label, values) => {
  const albumList = values
    .map(({metric_name, primary_label, primary_value, secondary_value}) => ({
      id: metric_name,
      label: primary_label,
      value: Number(primary_value),
      key: secondary_value,
    }))
    .sort((a, b) => parseFloat(a.key) - parseFloat(b.key));
  const newArray = {
    data: albumList,
    label: label,
  };
  return newArray;
};

export const _requestedSongsAlbums = (songs, albums) => {
  const newArray = {
    songs: songs,
    albums: albums,
  };
  return newArray;
};
