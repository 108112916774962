import React from 'react';
import styled from 'styled-components';
import {KPI} from 'Components';

export const Summary = ({items, type}) => {
  return (
    <Container>
      {items &&
        items.map((item, index) => {
          return <KPI key={index} type={type} {...item} />;
        })}
    </Container>
  );
};

const Container = styled.div`
  align-items: stretch;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  @media only screen and ${props => props.theme.media.small} {
    flex-direction: column;
  }
`;
