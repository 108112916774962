import React, {useState, useEffect} from 'react';
import styled from 'styled-components';
import {
  DonutChart,
  Legend,
  withDefaultMedia,
  Link,
  GridColumn,
  Grid,
  GridRow,
} from 'notes';
import {ChartLine} from 'Components';
import {StyledLabel} from './SingleCriteriaGraph';

const RequestedSongsLogic = ({songs, albums, matches}) => {
  const [albumItem, setAlbumItem] = useState({});
  const [songItem, setSongItem] = useState({});
  const [songList, setSongList] = useState('');
  const [songListIncrement, setSongListIncrement] = useState(1);

  useEffect(() => {
    if (!!songs.data) {
      const updateSongList = songs => {
        let unfilteredSongs = songs;
        let increment = songListIncrement * 10;
        let newArray = [];
        for (var i = 0; i < increment; i++) {
          if (unfilteredSongs[i]) {
            newArray.push(unfilteredSongs[i]);
          }
        }
        setSongList(newArray);
      };
      updateSongList(songs.data);
    }
  }, [songListIncrement, songs]);

  const handleHover = data => {
    setAlbumItem({id: data.id});
    const findSong = songs.data.filter(({albumId}) => albumId === data.id);
    setSongItem(findSong);
  };

  const checkHover = hoverId => {
    if (songItem.length > 0) {
      const tracks = songItem.find(({id}) => id === hoverId);
      return tracks;
    } else {
      return songItem.id === hoverId;
    }
  };

  return (
    <Container row columns={matches.large ? 10 : 4}>
      <ChartLineContainer
        columns={matches.large ? 6 : 4}
        columnRight={matches.large ? 1 : 0}
      >
        <LabelMargin>{songs.label}</LabelMargin>
        {!!songList &&
          songList.map(({id, label, value, albumId, percentage}) => (
            <ChartLine
              key={id}
              onMouseEnter={() => {
                setAlbumItem({id: albumId});
                setSongItem({id: id});
              }}
              onMouseLeave={() => {
                setAlbumItem({});
                setSongItem({});
              }}
              hover={checkHover(id)}
              scale
              items={songList}
              label={label}
              value={value}
              secondaryValue={percentage}
            />
          ))}
        <LinkContainer>
          {songList.length < songs.data.length && (
            <ExpandLink
              onClick={() => setSongListIncrement(songListIncrement + 1)}
            >
              View More
            </ExpandLink>
          )}
          {songListIncrement !== 1 && (
            <ExpandLink
              onClick={() => setSongListIncrement(songListIncrement - 1)}
            >
              View Less
            </ExpandLink>
          )}
        </LinkContainer>
      </ChartLineContainer>
      <DonutContainer
        id="print-state"
        removeSticky={albums.data.length > 9 ? true : false}
        columns={3}
      >
        <DonutLabel>{albums.label}</DonutLabel>
        <ChartMargin
          direction="column"
          data={albums.data}
          setRelatedHover={albumItem}
          title="Spend Distribution by Product"
          onHover={handleHover}
        />
        <Legend
          direction="column"
          setRelatedHover={albumItem}
          items={albums.data}
          onHover={handleHover}
        />
      </DonutContainer>
    </Container>
  );
};

export const RequestedSongs = withDefaultMedia(RequestedSongsLogic);

const LinkContainer = styled(GridRow)`
  margin-top: 24px;
`;

const ExpandLink = styled(Link)`
  display: inline-block;
  & + & {
    margin-left: 40px;
  }
`;

const Container = styled(Grid)`
  align-items: flex-start;
  @media only screen and ${props => props.theme.media.medium} {
    justify-content: space-between;
  }
  @media only screen and ${props => props.theme.media.small} {
    width: 100%;
  }
`;

const ChartLineContainer = styled(GridColumn)`
  @media only screen and ${props => props.theme.media.medium} {
    margin-right: 40px;
  }
  @media only screen and ${props => props.theme.media.small} {
    width: 100%;
  }
`;

const ChartMargin = styled(DonutChart)`
  margin-bottom: 40px;
`;

const LabelMargin = styled(StyledLabel)`
  margin-top: 0;
`;

const DonutLabel = styled(LabelMargin)`
  align-items: center;
`;

const DonutContainer = styled(GridColumn)`
  margin-bottom: 46px;
  @media only screen and (min-height: 724px) {
    position: ${props => (props.removeSticky ? 'realtive' : 'sticky')};
    top: ${props => (props.removeSticky ? 0 : '24px')};
  }
  @media only screen and ${props => props.theme.media.small} {
    display: none;
  }
`;
