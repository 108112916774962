import React, {useState, useContext, Fragment} from 'react';
import styled from 'styled-components';
import {useHistory} from 'react-router-dom';
import {
  Header,
  Filter,
  MobileFilterButton,
  Footer,
  ScrollToTop,
  MobileFilter,
  ExportModal,
} from 'Components';
import {Section} from 'Section';
import {withDefaultMedia, Loader, H3} from 'notes';
import {ReportContext} from 'Providers';
import {dataFilter} from '../modules';
import {useFirebaseContext} from 'hooks';

const MainLogic = ({match, matches}) => {
  let history = useHistory();
  const {setReport, artist, report, filters, user} = useContext(ReportContext);
  const [loading, setLoading] = useState(false);
  const [showFilterModal, setShowFilterModal] = useState(false);
  const [showExport, setShowExport] = useState(false);
  const [filterSelection, setFilterSelection] = useState({
    categoryId: 1,
    subcategoryId: 1,
  });

  const {callable} = useFirebaseContext();

  if (!artist.collector_id) {
    history.push(`/${match.params.slug}`);
  }

  const handleChange = data => {
    getReportData(data);
  };

  const getReportData = async selected => {
    const itemId = selected.subcategoryId;
    if (
      filterSelection.categoryId !== selected.categoryId ||
      filterSelection.subcategoryId !== selected.subcategoryId
    ) {
      if (filterSelection.subcategoryId !== selected.subcategoryId) {
        setLoading(true);
        try {
          const {data} = await callable('report-filter', {
            collectorId: match.params.collector,
            filterId: itemId,
            token: user,
          });
          var newList = dataFilter(data, selected.categoryId);
          setReport(newList);
          setFilterSelection(selected);
          setLoading(false);
        } catch (err) {
          console.log(err);
          setLoading(false);
        }
      }
    }
  };

  const getFilterTitle = () => {
    if (!!filters.filters) {
      var filterArray = filters.filters;
      var filterTitle =
        !!filterArray &&
        filterArray.find(
          ({filter_id}) => filter_id === filterSelection.subcategoryId
        );
      return filterTitle
        ? filterTitle.filter_name
        : filters.labels[0].filter_type;
    }
  };

  var filterTitle = getFilterTitle();

  return (
    <Fragment>
      <ExportModal
        filterName={filterTitle}
        setOpen={setShowExport}
        open={showExport}
      />
      <PageContainer isModalOpen={showExport} id="page-print">
        {!matches.large && !!filters && (
          <MobileFilter
            onChange={handleChange}
            setOpen={setShowFilterModal}
            open={showFilterModal}
            options={filters}
            selected={filterSelection}
          />
        )}
        <Header print={showExport} />
        {!matches.large ? (
          <MobileFilterButton
            title={filterTitle}
            onClick={() => setShowFilterModal(true)}
            loading={loading}
          />
        ) : (
          !!filters.labels && (
            <DesktopFilter
              onChange={handleChange}
              onExportClick={setShowExport}
              data={filters}
              loading={loading}
              print={showExport}
            />
          )
        )}
        {!!showExport && (
          <PrintFilterTitle>
            <span>{filterTitle}</span> Report
          </PrintFilterTitle>
        )}
        <Content>
          <ScrollToTop />
          {!loading && report ? (
            report.map((item, index) => {
              return <Section key={index} {...item} />;
            })
          ) : (
            <LoadingContainer>
              <Loader active />
            </LoadingContainer>
          )}
        </Content>
        <FooterStyle className="print-hide" dark />
      </PageContainer>
    </Fragment>
  );
};

export const Main = withDefaultMedia(MainLogic);

const DesktopFilter = styled(Filter)`
  ${props => props.print && 'display: none;'}
`;

const PrintFilterTitle = styled(H3)`
  color: ${props => props.theme.colors.disabledText};
  font-weight: 700;
  margin: 0 auto;
  max-width: 1016px;
  width: 100%;
  span {
    color: ${props => props.theme.colors.action};
  }
`;

const LoadingContainer = styled.div`
  display: flex;
  height: 40vh;
  width: 100%;
`;

const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  ${props =>
    props.isModalOpen &&
    `
    width: 1160px !important;
    .print-hide { visibility: hidden; height: 0; }
    #print-state { position: relative !important; top: 0 !important; }
  `};
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding-top: 0;
  position: relative;
  @media only screen and ${props => props.theme.media.large} {
    padding-top: 24px;
  }
`;

const FooterStyle = styled(Footer)`
  margin-top: -29px;
  z-index: 1;
  @media only screen and ${props => props.theme.media.large} {
    margin-top: -48px;
  }
`;
